import { useEffect, useCallback } from 'react';
import { LocationHook } from 'wouter';
// eslint-disable-next-line import/no-unresolved
import useBrowserLocation from 'wouter/use-location';

import { HookReturnValue, Path } from 'wouter/use-location';

class OnLeavePageListener {
  private listeners: (() => Promise<boolean>)[] = [];

  addListener(canLeave: () => Promise<boolean>) {
    this.listeners.push(canLeave);
  }

  removeListener(canLeave: () => Promise<boolean>) {
    const index = this.listeners.indexOf(canLeave);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }

  async canLeave() {
    const res = await Promise.all(this.listeners.map((listener) => listener()));
    return res.every((r) => r);
  }
}

const onLeavePageListener = new OnLeavePageListener();

export function useCanLeave(canLeave: () => Promise<boolean>) {
  useEffect(() => {
    onLeavePageListener.addListener(canLeave);
    return () => {
      onLeavePageListener.removeListener(canLeave);
    };
  }, [canLeave]);
}

export function useAppLocation(): HookReturnValue<LocationHook> {
  const [location, setLocation] = useBrowserLocation();
  const appSetLocation = useCallback(async (to: Path, options?: { replace?: boolean }) => {
    const canLeave = await onLeavePageListener.canLeave();
    if (canLeave) {
     setLocation(to, options);
    }
  }, [setLocation]);

  return [location, appSetLocation];
}

