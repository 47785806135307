import { useTranslation } from 'react-i18next';
import AppAnalyticsCountIcon from './AppAnalyticsCountIcon';
import { ReactComponent as CallAnsweredIcon } from '../../../../assets/Call answered.svg';
import { ReactComponent as CallThisMonthIcon } from '../../../../assets/Calls this month.svg';
import { useTotalCount, useTotalCountWithDelta } from '../../../../shared/appBackend/useMetrics';
import { ReactNode, useContext } from 'react';
import AppShowLoading from '../../../../shared/appUIFramework/components/AppShowLoading';
import { useFormatNumber } from '../../../../shared/formatters/formatNumber';
import { EntryAnalyticsContext } from '../EntryAnalyticsContext';

function CallRateSection(
  { icon, count, title, prevCount, prevTitle, isGreen, includePrevMonthInfo }: {
    icon: ReactNode;
    count?: number;
    title: string;
    prevCount?: number;
    prevTitle: string;
    isGreen?: boolean;
    includePrevMonthInfo?: boolean;
  },
) {
  const formatNumber = useFormatNumber();
  return (
    <div className='app-analytics-widget-big-icon-container app-no-border-right'>
      <div className='app-d-flex app-flex-column app-gap-10 app-align-items-center'>
        <div
          className={ `app-analytics-widget-big-icon app-analytics-widget-calls-big-icon ${
            isGreen ? 'app-analytics-widget-calls-big-icon-green' : ''
          }` }
        >
          <AppAnalyticsCountIcon icon={ icon } count={ count } title={ title } />
        </div>
        { includePrevMonthInfo && (
          <div className='app-d-flex app-flex-column app-gap-5 app-align-items-center'>
            <AppShowLoading inline showLoading={ prevCount == null }>
              <div className='app-font-21 app-weight-600 app-color-primary-black'>
                { formatNumber(prevCount) }
              </div>
              <div className='app-font-12 app-uppercase app-weight-600 app-color-primary-black'>
                { prevTitle }
              </div>
            </AppShowLoading>
          </div>
        ) }
      </div>
    </div>
  );
}

export default function CallRateWidget({ width50 }: { width50?: boolean }) {
  const { t } = useTranslation();
  const { startDate, endDate } = useContext(EntryAnalyticsContext);
  const now = new Date();
  const showTotalCount = now.getMonth() === startDate.getMonth() && now.getFullYear() === startDate.getFullYear()
    && now.getMonth() === endDate.getMonth() && now.getFullYear() === endDate.getFullYear();
  const { totalCount, totalCountDelta, isDeltaRequired } = useTotalCountWithDelta();
  const prevMonthTotalCount = useTotalCount({ prevMonth: true });

  return (
    <div className={ `app-home-widget app-home-widget-call-rate ${width50 ? 'app-w-50pcnt' : ''}` }>
      <div className='app-home-widget-title app-mb-46'>
        <span className='app-home-widget-title-text'>{ t('CallRate') }</span>
      </div>
      <div className='app-d-flex app-gap-20 app-mb-25'>
        <CallRateSection
          icon={ <CallThisMonthIcon /> }
          count={ showTotalCount || !isDeltaRequired
            ? totalCount?.callCount
            : totalCountDelta?.callCount }
          title={ t('CallsThisMonth') }
          prevTitle={ t('CallsLastMonth') }
          includePrevMonthInfo={ showTotalCount }
          prevCount={ prevMonthTotalCount?.callCount }
        />
        <CallRateSection
          icon={ <CallAnsweredIcon /> }
          count={ showTotalCount || !isDeltaRequired
            ? totalCount?.callAnsweredCount
            : totalCountDelta?.callAnsweredCount }
          title={ t('CallsAnsweredThisMonth') }
          prevTitle={ t('CallsAnsweredLastMonth') }
          includePrevMonthInfo={ showTotalCount }
          prevCount={ prevMonthTotalCount?.callAnsweredCount }
          isGreen
        />
      </div>
    </div>
  );
}
