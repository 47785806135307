export enum Formats {
  Time24,
  DayMonthYearTime12,
  DateCommon,
  LongDate,
  ShortMonthYear,
}

export function formatDate(dateArg?: Date | string, format = Formats.Time24) {
  if (!dateArg) {
    return '';
  }

  let parts = [];
  let day, month, year, hour, minute, dayPeriod;
  const date = typeof dateArg === 'string' ? new Date(dateArg) : dateArg;
  switch (format) {
    case Formats.ShortMonthYear:
      parts = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        year: '2-digit' 
      }).formatToParts(date);
      month = parts.find(part => part.type === 'month')?.value;
      year = parts.find(part => part.type === 'year')?.value;

      return `${month}, ${year}`;
    case Formats.Time24:
      return date.toLocaleTimeString('en-US', { hour12: false });
    case Formats.DayMonthYearTime12:
      parts = new Intl.DateTimeFormat('en-US', {
        hour12: true,
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).formatToParts(date);
      day = parts.find(part => part.type === 'day')?.value;
      month = parts.find(part => part.type === 'month')?.value;
      year = parts.find(part => part.type === 'year')?.value;
      hour = parts.find(part => part.type === 'hour')?.value;
      minute = parts.find(part => part.type === 'minute')?.value;
      dayPeriod = parts.find(part => part.type === 'dayPeriod')?.value;
      return `${day} ${month} ${year} ${hour}:${minute} ${dayPeriod}`;
    case Formats.DateCommon:
      parts = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).formatToParts(date);
      day = parts.find(part => part.type === 'day')?.value;
      month = parts.find(part => part.type === 'month')?.value;
      year = parts.find(part => part.type === 'year')?.value;

      return `${day}/${month}/${year}`;
    case Formats.LongDate:
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      });
    default:
      return date.toLocaleTimeString('en-US');
  }
}

export function formatMsToTime(ms: number) {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const time = {
    days: days,
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: seconds % 60,
  };

  const pad = (num: number) => num.toString().padStart(2, '0');

  return `${time.days ? `${time.days}d ` : ''}${pad(time.hours)}:${pad(time.minutes)}:${pad(time.seconds)}`;
}

export function useFormatDate() {
  return formatDate;
}

export function useFormatDateFromEpoch() {
  return (epoch: number | null | undefined, format = Formats.Time24) => {
    if (!epoch) {
      return '';
    }
    const date = new Date(epoch);
    return formatDate(date, format);
  };
}
