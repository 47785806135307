import {AppPopupLayout} from "../../../shared/appUIFramework/popup/AppPopupLayout";
import {useTranslation} from "react-i18next";
import {closePopup} from "../../../shared/appUIFramework/popup/AppPopup";
import {ReactComponent as DisableBilling} from "../../../assets/icons/company white list/Disable billing.svg";

export default function AddToWhiteListConfirmation() {
  const {t} = useTranslation();
  
  const confirm = () => {
    closePopup(true);
  }

  const cancel = () => {
    closePopup(false);
  }

  return (
    <AppPopupLayout
      extraClass="app-popup-without-title"
      headerTitle={t('DisableBilling')}
      popupBody={(
        <>
          <div className="app-d-flex app-flex-column app-align-items-center app-justify-content-center">
            <DisableBilling className="app-w-133 app-h-118"/>
          </div>
          <div className="app-popup-info">
            {t('AreYouSureYouWishToDisableBillingForThisCompany')}
          </div>
        </>
      )}
      buttons={(
        <>
          <button onClick={cancel}
                  type="button"
                  className="app-popup-left-button app-secondary-button">{t('Cancel')}</button>
          <button onClick={confirm}
                  type="button"
                  className="app-popup-right-button app-primary-button">{t('Yes')}</button>
        </>
      )}
    />
  );
}