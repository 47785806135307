import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { useState } from 'react';
import { EMAIL_PATTERN } from '../../../shared/constants';

export default function AddEmailPopup() {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);

  const errors = {
    email: {
      pattern: !new RegExp(EMAIL_PATTERN).test(email),
      required: !email,
    },
  };

  const anyErrors = Object.values(errors.email).some(Boolean);


  const updateEmail = (email: string) => {
    setEmail(email);
  };

  const saveEmail = () => {
    closePopup(email);
  };

  return (
    <AppPopupLayout
      extraClass='app-popup-without-title'
      headerTitle={t('AddEmail')}
      popupBody={
        <>
          <div
            className='app-form-control'
            aria-invalid={touched && anyErrors}
          >
            <div className='app-form-control-label'>
              {t('Email')}
              <span className='app-text-secondary-red-color'>&nbsp;*</span>
            </div>
            <div className='app-form-control-input'>
              <input
                value={email}
                onChange={e => updateEmail(e.target.value)}
                onBlur={() => setTouched(true)}
                style={{ maxWidth: '543px' }}
                type='text'
                className='app-form-input app-form-input-outlined'
              />
            </div>
            {touched && anyErrors && (
              <div className='app-form-error'>
                {errors.email.required && t('FieldIsRequired')}
                {!errors.email.required && errors.email.pattern && t('InvalidEmail')}
              </div>
            )}
          </div>
        </>
      }
      buttons={
        <>
          <button onClick={() => closePopup(false)} type='button' className='app-popup-left-button app-secondary-button'>
            {t('Cancel')}
          </button>
          <button
            onClick={saveEmail}
            type='button'
            disabled={anyErrors}
            className='app-popup-right-button app-primary-button app-disabled-white-text'
          >
            {t('Save')}
          </button>
        </>
      }
    />
  );
}

