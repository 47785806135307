import { ReactNode } from 'react';
import AccessForbidden from './AccessForbidden';
import { UserRole, useAppClaims } from '../../backend/auth/auth.claims';

function Forbidden({ variant }: { variant: 'component' | 'page' }) {
  return (
    <>
      {variant === 'component' ? <></> : <AccessForbidden />}
    </>
  );
}

export function useIsAdmin() {
  const claims = useAppClaims();
  return claims.roles.includes(UserRole.Admin);
}

function AppPermissionGuard({
  requiredRoles,
  children,
  variant,
}: {
  requiredRoles: UserRole[];
  variant: 'component' | 'page';
  customAction?: () => boolean;
  children: ReactNode;
}) {
  const claims = useAppClaims();
  const hasAnyOfRequiredRoles = requiredRoles.some(p => claims.roles.includes(p));

  return hasAnyOfRequiredRoles
    ? children
    : <Forbidden variant={variant} />;
}

export function AppPermissionPageGuard({
  requiredRoles,
  children,
}: { requiredRoles: UserRole[], children: ReactNode }) {
  return (
    <AppPermissionGuard
      requiredRoles={requiredRoles}
      variant='page'
    >
      {children}
    </AppPermissionGuard>
  );
}

export function AppPermissionComponentGuard({
  requiredRoles: permissions,
  customAction,
  children,
}: { requiredRoles?: UserRole[], children: ReactNode, customAction?: () => boolean }) {
  return (
    <AppPermissionGuard
      requiredRoles={permissions || []}
      customAction={customAction}
      variant='component'
    >
      {children}
    </AppPermissionGuard>
  );
}
