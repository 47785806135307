import {useTranslation} from "react-i18next";
import {AppPopupLayout} from "../../shared/appUIFramework/popup/AppPopupLayout";
import {closePopup} from "../../shared/appUIFramework/popup/AppPopup";
import {ReactComponent as SiteSuccessIcon} from "../../assets/icons/Popup Icons/TransferSiteSuccessIcon.svg";

export default function TransferSiteSuccessPopup({singleSite, fromInstaller, toInstaller}: {
    singleSite: string | undefined,
    fromInstaller?: string,
    toInstaller?: string
}) {
    const {t} = useTranslation();
    const cancel = () => closePopup(false);

    return (
        <AppPopupLayout
            headerTitle={t('Popup_TransferSite_Header')}
            onHeaderClose={cancel}
            popupBody={(
                <>
                    <div className="app-popup-image">
                        <SiteSuccessIcon/>
                    </div>
                    <div className="app-font-20 app-popup-body-text app-uppercase app-weight-600">
                        {singleSite ? t('Popup_TransferSiteSuccess_BodyHeader') : t('Popup_TransferMultipleSitesSuccess_BodyHeader')}
                    </div>
                    <div className="app-font-20 app-popup-body-text">
                        {singleSite ? t('Popup_TransferSiteSuccess_Body',{ singleSite, fromInstaller, toInstaller }) : t('Popup_TransferMultipleSiteSuccess_Body',{fromInstaller, toInstaller })}
                    </div>
                </>
            )}
        />
    );
}