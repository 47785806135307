import { useTranslation } from 'react-i18next';
import { useFormatNumber } from '../../../../shared/formatters/formatNumber';
import UsersIcon from '../../../../assets/icons/home page icons/Entry users.svg';
import { useTotalCountWithDelta } from '../../../../shared/appBackend/useMetrics';
import AppShowLoading from '../../../../shared/appUIFramework/components/AppShowLoading';
import { EntryAnalyticsContext } from '../EntryAnalyticsContext';
import { useContext } from 'react';
import { MobileTypeFilter } from '../../components/MobileTypeDropdown';
import AppAnalyticsDelta from './AppAnalyticsDelta';

export default function UsersWidget() {
  const { t } = useTranslation();
  const { mobileTypeFilter } = useContext(EntryAnalyticsContext);
  const { totalCount, totalCountDelta, isDeltaRequired } = useTotalCountWithDelta();
  const formatNumber = useFormatNumber();

  const counts: Record<MobileTypeFilter, number | undefined> = {
    [MobileTypeFilter.All]: totalCount?.mobileUsersCount,
    [MobileTypeFilter.ApnCallKit]: totalCount?.androidUsersCount,
    [MobileTypeFilter.Fcm]: totalCount?.iosUsersCount,
  };

  const deltas: Record<MobileTypeFilter, number | undefined> = {
    [MobileTypeFilter.All]: totalCountDelta?.mobileUsersCount,
    [MobileTypeFilter.ApnCallKit]: totalCountDelta?.androidUsersCount,
    [MobileTypeFilter.Fcm]: totalCountDelta?.iosUsersCount,
  };

  const count = counts[mobileTypeFilter || MobileTypeFilter.All];
  const delta = deltas[mobileTypeFilter || MobileTypeFilter.All];

  return (
    <div className='app-home-widget app-home-widget-users'>
      <div className='app-home-widget-title app-mb-46'>
        <span className='app-home-widget-title-text'>{ t('Users') }</span>
      </div>
      <div className='app-home-users-icon app-mb-25'>
        <img src={ UsersIcon } alt='entry users icon' />
      </div>
      <div className='app-home-users-count app-mb-10'>
        <AppShowLoading inline showLoading={ totalCount == null  || (!!isDeltaRequired && totalCountDelta == null)}>
          { formatNumber(count) }
          {totalCountDelta != null && <AppAnalyticsDelta delta={delta || 0} />}
        </AppShowLoading>
      </div>
      <div className='app-text-align-center app-uppercase app-mb-25 app-font-12 app-weight-600'>
        { t('EntryAppUsers') }
      </div>
    </div>
  );
}
