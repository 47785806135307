import { useTranslation } from "react-i18next";
import { AppPopupLayout } from "../../shared/appUIFramework/popup/AppPopupLayout";
import { closePopup } from "../../shared/appUIFramework/popup/AppPopup";
import { ReactComponent as EmailIcon } from "../../assets/InvoicefailedtoGenerate.svg";

export default function InvoiceCompanyNotFoundPopup() {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      headerTitle={'InvoiceFailedToGenerate'}
      onHeaderClose={closePopup}
      popupBody={
        <div className='app-d-flex app-flex-column app-align-items-center app-justify-content-center'>
          <EmailIcon className='app-w-160 app-h-128' />
          <span className='app-d-inline-block app-mw-500 app-pt-65'>{t('InvoicePDFFailedToGenerateCompanyNotFound')}</span>
        </div>
      }
    />
  );
}
