import { useSWRAndHandleErrors } from './swr/useSWRAndHandleErrors';
import { FunctionAppType, Urls } from '../backend/urls';
import { httpGetJson } from '../backend/http/http';

export enum ServiceStatus {
  Good = 'Good',
  ServiceError = 'ServiceError',
  ServiceOutage = 'ServiceOutage',
}

export interface IServiceHealthSummary {
  name: string;
  lastUpdatedAt: Date;
  status: ServiceStatus;
  possibleIssuesIfBroken: string[];
}

export function useServiceHealthSummary(): IServiceHealthSummary[] | undefined {
  const { data } = useSWRAndHandleErrors<IServiceHealthSummary[]>(Urls.ServiceHealthSummary, httpGetJson);
  return data;
}

interface IServiceHealthDetails {
  operationName: string;
  exception: string;
  errorDetails: string;
  errorsCount: number;
}

export function useServiceHealthDetails(functionApp: FunctionAppType) {
  const { data } = useSWRAndHandleErrors<IServiceHealthDetails[]>(Urls.ServiceHealthDetails(functionApp), httpGetJson);
  return data;
}
