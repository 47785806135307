import {ReactNode} from "react";
import {useFormatNumber} from "../../../../shared/formatters/formatNumber";
import AppShowLoading from "../../../../shared/appUIFramework/components/AppShowLoading";
import AppAnalyticsDelta from "./AppAnalyticsDelta";

export default function AppAnalyticsSmallIcon({icon, count, delta, isDeltaRequired, title}: { icon: ReactNode, count?: number, delta?: number, isDeltaRequired?: boolean,  title: string }) {
  const formatNumber = useFormatNumber();
  return <div className="app-analytics-small-icon">
    <div className="app-analytics-small-icon-icon">
      {icon}
    </div>
    <div className="app-analytics-small-icon-count">
      <AppShowLoading inline showLoading={count == null || (!!isDeltaRequired && delta == null)}>
        <div className="app-text-align-center">{formatNumber(count)}</div>
        {delta != null && <AppAnalyticsDelta delta={delta} />}
      </AppShowLoading>
    </div>
    <div className="app-analytics-small-icon-title">
      {title}
    </div>
  </div>;
}
