import { ICompanyAddress } from "../appBackend/useCompanies";

export function formatCompanyAddress(address?: ICompanyAddress): string {
  if (!address) {
    return '';
  }

  const {
    address1,
    address2,
    city,
    postCode,
  } = address;
  return [address1 || '', address2 || '', city || '', postCode || '']
    .filter(item => !!item)
    .join(', ');
}

