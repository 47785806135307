import { useEffect } from "react";

export function getFirstNCharacters(str: string, n: number): string {
    return str.substring(0, n);
}

export function debounce(func: Function, timeout = 300) {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    // @ts-ignore
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

// calls the action in debounced manner when the action has been changed
export function useDebounceCall(action : () => void, debounceTime: number)  {
  useEffect(() => {
    const timeout = setTimeout(() => {
      action();
    }, debounceTime);

    return () => clearTimeout(timeout);
  }, [action, debounceTime]);
}

