import {useState} from "react";
import { ICompany } from "../../shared/appBackend/useCompanies";

interface ITransferSystemState {
  currentCompany?: ICompany | null;
  newCompany?: ICompany | null;
  sitesToTransferNames: string[];
  sitesSearchString: string;
}

export function useTransferSitesState() {
  const [state, setState] = useState<ITransferSystemState>({
    sitesToTransferNames: [],
    sitesSearchString: '',
  });

  return {
    state,
    setCurrentCompany: (company: ICompany | null) => {
      setState({
        ...state,
        currentCompany: company,
        sitesToTransferNames: [],
        sitesSearchString: '',
      })
    },
    setNewCompany: (company: ICompany | null) => {
      setState({
        ...state,
        newCompany: company,
      })
    },
    setSystemsSearchString: (search: string) => {
      setState({
        ...state,
        sitesSearchString: search
      })
    },
    toggleAllSitesToTransferNames: (systems: string[]) => {
      const allSystemsSelected = state.sitesToTransferNames.length === systems.length;
      setState({
        ...state,
        sitesToTransferNames: allSystemsSelected ? [] : systems
      })
    },
    toggleSiteToTransferName: (system: string) => {
      const systemIndex = state.sitesToTransferNames.indexOf(system);
      setState({
        ...state,
        sitesToTransferNames: systemIndex === -1
          ? [...state.sitesToTransferNames, system]
          : state.sitesToTransferNames.filter((p) => p !== system)
      })
    },
  }
}
