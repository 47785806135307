import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { ReactNode } from 'react';
import { disableAuth } from '../disableAuth';
import { ClaimsProvider } from './auth.claims';
import { AppMsalProvider, useAppMsal } from './auth.msal';

function MsalWrapper({ authenticatedTemplate, unauthenticatedTemplate }: {
  authenticatedTemplate: ReactNode;
  unauthenticatedTemplate: ReactNode;
}) {
  const msal = useAppMsal();

  return (
    <MsalProvider instance={msal.instance}>
      <AuthenticatedTemplate>
        <ClaimsProvider>
          {authenticatedTemplate}
        </ClaimsProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {unauthenticatedTemplate}
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export function AuthProvider({ authenticatedTemplate, unauthenticatedTemplate }: {
  authenticatedTemplate: ReactNode;
  unauthenticatedTemplate: ReactNode;
}) {
  return (
    <AppMsalProvider>
      {disableAuth
        ? (
          <ClaimsProvider>
            {authenticatedTemplate}
          </ClaimsProvider>
        )
        : (
          <MsalWrapper
            authenticatedTemplate={authenticatedTemplate}
            unauthenticatedTemplate={unauthenticatedTemplate}
          />
        )}
    </AppMsalProvider>
  );
}

