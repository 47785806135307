import { useTranslation } from "react-i18next";
import HomeShell from "../components/HomeShell";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/Delete icon.svg';
import { updateEmails, useEmails } from "../../../shared/appBackend/useEmails";
import AppShowLoading from "../../../shared/appUIFramework/components/AppShowLoading";
import { showPopup } from "../../../shared/appUIFramework/popup/AppPopup";
import AppRequestFailedPopup from "../../../shared/appUIFramework/components/AppRequestFailedPopup";
import './WhiteList.scss';
import AddEmailPopup from "./AddEmailPopup";
import { useAppTableScrollStyles } from "../../../styles";

export default function WhiteList() {
	const { t } = useTranslation();
	const [emails, setEmails] = useState<string[]>([]);
	const [search, setSearch] = useState<string>();
	const filteredEmails = !search ? emails : emails.filter(p => p.toUpperCase().includes(search.toUpperCase()))
	const { data: originalEmails } = useEmails();
	const emailsLoading = originalEmails == null;
  const tableContentRef = useRef<HTMLDivElement | null>(null);

  const isScrollVisible = useAppTableScrollStyles({
    isTableVisible: !emailsLoading,
    tableContentRef
  });

	useEffect(() => {
		if (originalEmails) {
			setEmails(originalEmails.sort((a, b) => a.localeCompare(b)));
		}
	}, [originalEmails]); 

	const [saving, setSaving] = useState(false)

	const save = async (emailsToSave: string[]) => {
		try {
			setSaving(true);
			await updateEmails(emailsToSave);
			setSaving(false);
			setEmails(emailsToSave);
		} catch (e) {
			console.log(e);
			await showPopup(<AppRequestFailedPopup />)
		} finally {
			setSaving(false);
		}
	}
	const addEmail = async () => {
		const email = await showPopup(<AddEmailPopup />);
		if (email) {
			await save([...emails, email]);
		}
	};

	const removeEmail = async (email: string) => {
		const newEmails = emails.filter(p => p !== email);
		await save(newEmails);
	};

	return <HomeShell activeTab={'whitelist'}>
		<AppShowLoading showLoading={emailsLoading || saving}>
			<div className="app-d-flex app-justify-content-between app-align-items-center">
				<div className="app-form-control app-form-control-search app-mw-500">
					<div className="app-form-control-input app-no-ml">
						<div className="app-form-control-search-icon">
							<SearchIcon />
						</div>
						<input type="text"
							placeholder={t('SearchEmails')}
							value={search}
							className="app-transfer-list-search"
							onChange={(event) => setSearch(event.target.value)} />
					</div>
				</div>
				<div>
					<button onClick={addEmail} type="button" className="app-primary-button app-uppercase">{t('AddEmail')}</button>
				</div>
			</div>
      <div className="app-font-20 app-weight-600 app-pl-20 app-pt-20 app-uppercase">
        {t('Emails')}
      </div>
			<div ref={tableContentRef} className={`app-flex-vertical-scrollable ${isScrollVisible ? 'app-pr-20' : ''}`}>
				<div className="app-d-flex app-flex-column app-gap-20">
					{filteredEmails.map((email, index) => {
						return (
							<div className="app-white-list-email app-table-content-row" key={index}>
								<span>{email}</span>
								<div onClick={() => removeEmail(email)}>
									<DeleteIcon />
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</AppShowLoading>
	</HomeShell>
}
