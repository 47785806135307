import { SortOrder } from '../appUIFramework/hooks/useSortStringOptions';
import { getUrlWithQueryParams, httpGetString, httpPost } from '../backend/http/http';
import { Urls } from '../backend/urls';
import { useSWRAndHandleErrors } from './swr/useSWRAndHandleErrors';

export interface IEmailLogFilter {
  search: string;
}

export interface IEmailLogSort {
  dateSentSort?: SortOrder;
  scheduledForSort?: SortOrder;
}

export enum EmailLogItemType {
  Unkonwn = 0,
  InfrastructureUpgrade = 1,
  SoftwareUpgrade = 2,
}

export interface IEmailLogItem {
  id: string;
  emailType: EmailLogItemType;
  name: string;
  dateSent: string;
  scheduledFor: string;
}

export function useEmailLogCount(filter: IEmailLogFilter): number | null {
  const url = getUrlWithQueryParams(Urls.EmailLogTotals, filter);
  const { data } = useSWRAndHandleErrors(url, httpGetString);

  return data ? +data : null;
}

interface ISendScheduledEmails {
  emailType: EmailLogItemType;
  date: Date;
  timeFrom: Date;
  timeTo: Date;
}

export async function sendScheduledEmails(body: ISendScheduledEmails): Promise<void> {
  await httpPost(Urls.EmailLog, JSON.stringify(body));
}
