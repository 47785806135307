import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { InvoicingSortOrder } from '../appUIFramework/hooks/useSortStringOptions';
import { getUrlWithQueryParams, httpGetJson } from '../backend/http/http';
import { Urls } from '../backend/urls';
import { handleHttpRequestError, useSWRAndHandleErrors } from './swr/useSWRAndHandleErrors';
import { useRef } from 'react';
import { roundAsCurrencyOnUI } from '../formatters/formatMoney';

export enum AccountType {
  RegisteredInstaller = 'Registered Installer', // Installer that is registered with Paxton rewards
  Installer = 'Installer', // Installer not signed up to Paxton rewards
  SystemManager = 'System manager', // Email is in contacts but branch is not of type Installer
  Unkonwn = 'Unknown', // Email is not in contacts
}

export enum VatEvidenceType {
  None,
  VatNo,
  TaxCertificate,
}

export enum InvoicePaymentType {
  Unknown = 0,
  Auto = 1,
  Manual = 2,
}

export enum InvoiceStatus {
  Unknown = 0,
  Unpaid = 1,
  Pending = 2,
  Paid = 3,
  Failed = 4,
  Overdue = 5,
}

export enum CurrencyCode {
  GBP = 0,
  EUR = 1,
  USD = 2,
}

export enum CurrencyCodeString {
  GBP = 'GBP',
  EUR = 'EUR',
  USD = 'USD',
}
export enum CurrencyCodeSymbol {
  GBP = '£',
  EUR = '€',
  USD = '$',
}

export interface IInvoice {
  no: number;
  date: string;
  total: number;
  currency: CurrencyCode;
  type: InvoicePaymentType;
  status: InvoiceStatus;
  customerReference: string;
}

export enum DiscountType {
  None = 0,
  InstallerTierDiscount = 6,
  DiscretionaryDiscount = 7,
}

enum InstallerLoyaltyTierEnum {
  None = 0,
  LoyaltyPaxtonPartner,
  LoyaltySilverPartner,
  LoyaltyGoldPartner,
  LoyaltyPlatinumPartner,
}

enum ProductEnum {
  Unknown = 0,
  EntryApp = 1,
  P10Cloud = 2,
}

export interface IBillableUnit {
  productId: ProductEnum;
  salesCode: string;
  description: string;
  discountType: DiscountType;
  numberOfUnits: number;
  retailPrice: number;
  billableUnitUniqueId: string;
}

export interface IDiscount {
  typeOfDiscountId: DiscountType;
  installerLoyaltyTierId: InstallerLoyaltyTierEnum;
  discountPercent: number;
  discountAppliedOrder: number;
}

interface IInvoiceCost {
  discountsApplied: IDiscount[];
  currencyCodeId: CurrencyCode;
  conversionFactor: number;
  billableUnits: IBillableUnit[];
  vatPercentage: number;
}

interface IInvoiceDetailsAddress {
  companyName: string;
  address1: string;
  address2: string;
  townCity: string;
  country: string;
  postcode: string;
  county: string;
}

export enum InvoicePaymentMethod {
  Unknown = 0,
  Auto = 1,
  Invoice = 2,
}

interface IBillableUnitWithCost extends IBillableUnit {
  SiteCost: number;
  DiscountedSiteCost: number;
  SiteDiscount: number;
}

interface IInvoiceTotals {
  Subtotal: number;
  Vat: number;
  Total: number;
  TotalDiscount: number;
  BillableUnitsWithCosts: IBillableUnitWithCost[];
}

export interface IInvoiceEntry extends IInvoiceCost {
  id: string;
  applicationId: number;
  branchOfficeId: number;
  customerReference: string;
  invoiceId: number;
  auditDateTime: string;
  invoiceStatusId: InvoiceStatus;
  InvoicePaymentMethodId: InvoicePaymentMethod;
  email: string;
  orderId: number;
  orderDate: string;
  invoiceDate: string;
  paymentDueDate: string;
  invoiceAddress: IInvoiceDetailsAddress;
  salesRegionName: string;
  salesRegionId: number;
  billingId: number;
  environment: string;
  Totals: IInvoiceTotals;

  // not implemented
  customerVatNumber: string;
}

export interface IInvoicesFilter {
  customerReference?: string;
  invoiceNoSearch?: string;
  application: 'EntryApp';
}

export interface IInvoicesQueryParams extends IInvoicesFilter {
  dateSort: InvoicingSortOrder;
}

export interface IInvoicesTotalNumber {
  totalNumber: number;
}

export function useInvoicesTotalNumber(queryParams: IInvoicesFilter) {
  const url = Urls.InvoicesTotalNumber;
  const urlWithFilter = getUrlWithQueryParams(url, queryParams);
  const { data } = useSWRAndHandleErrors<IInvoicesTotalNumber>(urlWithFilter, httpGetJson);

  return data?.totalNumber;
}

export function fillNotImplementedValues() {
  return (p: IInvoiceEntry) => ({
    ...p,
  } as IInvoiceEntry);
}

export async function getInvoice(invoiceId: number, customerReference: string): Promise<IInvoiceEntry | null> {
  // todo: refactor to use global mutate from useSWR to update cache for the invoice details
  // that will improve performance when opening details for the invoice that was already loaded
  try {
    const url = Urls.Invoice(customerReference, invoiceId);
    return httpGetJson<IInvoiceEntry>(url).then(fillNotImplementedValues());
  } catch (e) {
    // log left to log if any js error happened in try
    // eslint-disable-next-line no-console
    console.error(e);
    await handleHttpRequestError(e);
    return null;
  }
}

export function useInvoice(invoiceId: number, customerReference: string) {
  const url = Urls.Invoice(customerReference, invoiceId);
  const { data } = useSWRAndHandleErrors<IInvoiceEntry>(url, () => httpGetJson<IInvoiceEntry>(url).then(fillNotImplementedValues()));

  return { invoice: data };
}

const CurrencyKeys = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD',
};

export function useCurrencyOptions() {
  const { t } = useTranslation();
  const currencyTranslationsRef = useRef<Record<CurrencyCode, string>>({
    [CurrencyCode.GBP]: t(CurrencyKeys.GBP),
    [CurrencyCode.EUR]: t(CurrencyKeys.EUR),
    [CurrencyCode.USD]: t(CurrencyKeys.USD),
  });

  const currencyTranslations = currencyTranslationsRef.current;
  const options = Object.keys(currencyTranslations).map(c => +c) as CurrencyCode[];

  return { options, getOptionLabel: (option: CurrencyCode) => currencyTranslations[option] };
}

export function getCurrencyOptionTranslation(currency: CurrencyCode) {
  return i18n.t({
    [CurrencyCode.GBP]: CurrencyKeys.GBP,
    [CurrencyCode.EUR]: CurrencyKeys.EUR,
    [CurrencyCode.USD]: CurrencyKeys.USD,
  }[currency]);
}

export enum InstallerStatus {
  InReview = 0,
  Partnered,
  Silver,
  Gold,
  Platinum,
}

export interface IInternalAppsCompanyAddress {
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  postCode: string;
  country: string;
  countryId: number;
}

export interface InternalAppsCompany {
  branchOfficeId: number;
  customerReference: string;
  address: IInternalAppsCompanyAddress;
  typeOfCustomer: string;
  cardType: string;
  maskedCardNumber: string;
  installerLoyaltyTier: InstallerStatus;
  loyaltyDiscount: number;
  registrationDate: string;
  telephone: string;
  branchContact: {
    fullName: string;
    position: string;
    email: string;
    telephone: string;
    errorMessage: string;
    errorId: number;
  };
  ErrorMessage: string;
  ErrorId: number;
  taxReference: string;
  paymentTermsDays: number;
}

export function getInternalAppsCompanyDetails(customerReference: string) {
  return httpGetJson<InternalAppsCompany>(Urls.InternalAppsCustomerDetails(customerReference));
}

export function useInternalAppsCompanyDetails(customerReference: string) {
  const swrKey = Urls.InternalAppsCustomerDetails(customerReference);
  const { data, mutate } = useSWRAndHandleErrors<InternalAppsCompany>(
    swrKey,
    () => getInternalAppsCompanyDetails(customerReference),
  );
  return { data, mutate };
}

export enum CountryCode {
  DE = 'DE',
  FR = 'FR',
  GB = 'GB',
  NL = 'NL',
  US = 'US',
  IRL = 'IE',
  BE = 'BE',
  LUX = 'LU',
  ZA = 'ZA',
  CAN = 'CA',
  SWE = 'SE',
  NOR = 'NO',
  DNK = 'DK',
}

interface ICountryConfiguration {
  countryId: number; // value required for internal apps during sign up
  countryCode: CountryCode; // country code or iso code
  mobileCodes: string[]; // should be array, because some countries in future may have multiple mobile codes
  name: string; // country name in english, e.g. United Kingdom
  vatEvidence?: VatEvidenceType;
  defaultLanguage: B2CLangCode;
}

export enum B2CLangCode {
  English = 'en-GB',
  French = 'fr',
  EnglishUS = 'en-US',
  German = 'de',
  Netherlands = 'nl',
  Swedish = 'sv',
  Danish = 'da',
  Norwegian = 'no',
  SpanishLatinAmerica = 'es-419',
}

const countries: ICountryConfiguration[] = [
  {
    countryId: 55,
    countryCode: CountryCode.DE,
    mobileCodes: ['49'],
    name: 'Germany',
    vatEvidence: VatEvidenceType.None,
    defaultLanguage: B2CLangCode.German,
  },
  {
    countryId: 73,
    countryCode: CountryCode.FR,
    mobileCodes: ['33'],
    name: 'France',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.French,
  },
  {
    countryId: 75,
    countryCode: CountryCode.GB,
    mobileCodes: ['44'],
    name: 'United Kingdom',
    vatEvidence: VatEvidenceType.None,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 163,
    countryCode: CountryCode.NL,
    mobileCodes: ['31'],
    name: 'Netherlands',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.Netherlands,
  },
  {
    countryId: 228,
    countryCode: CountryCode.US,
    mobileCodes: ['1'],
    name: 'United States',
    vatEvidence: VatEvidenceType.TaxCertificate,
    defaultLanguage: B2CLangCode.EnglishUS,
  },
  {
    countryId: 100,
    countryCode: CountryCode.IRL,
    mobileCodes: ['353'],
    name: 'Ireland',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 21,
    countryCode: CountryCode.BE,
    mobileCodes: ['32'],
    name: 'Belgium',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.French,
  },
  {
    countryId: 132,
    countryCode: CountryCode.LUX,
    mobileCodes: ['352'],
    name: 'Luxembourg',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 242,
    countryCode: CountryCode.ZA,
    mobileCodes: ['27'],
    name: 'South Africa',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 37,
    countryCode: CountryCode.CAN,
    mobileCodes: ['1'],
    name: 'Canada',
    vatEvidence: VatEvidenceType.TaxCertificate,
    defaultLanguage: B2CLangCode.EnglishUS,
  },
  {
    countryId: 194,
    countryCode: CountryCode.SWE,
    mobileCodes: ['46'],
    name: 'Sweden',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.Swedish,
  },
  {
    countryId: 164,
    countryCode: CountryCode.NOR,
    mobileCodes: ['47'],
    name: 'Norway',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 57,
    countryCode: CountryCode.DNK,
    mobileCodes: ['45'],
    name: 'Denmark',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
];

export const countryList = countries
  .sort((a, b) => a.name.localeCompare(b.name));

export function isTaxMethodUsedInCompany(internalAppsCompany: InternalAppsCompany, evidenceType: VatEvidenceType) {
  const countryVatEvidence = countryList.find(country => country.countryId === internalAppsCompany.address.countryId)
    ?.vatEvidence;
  return countryVatEvidence === evidenceType;
}

export function getTotalUnitsPrice(unitPrice: number, numberOfUnits: number) {
  return unitPrice * numberOfUnits;
}

export function getDiscountPercent(discounts: IDiscount[], type: DiscountType) {
  const discount = discounts.find(p => p.typeOfDiscountId === type);
  if (discount && (discount.discountPercent > 1 || discount.discountPercent < 0)) {
    throw new Error('Discount should be between 0 and 1');
  }
  return discount?.discountPercent || 0;
}

export function getDiscountMultiplier(discounts: IDiscount[], type: DiscountType) {
  return 1 - getDiscountPercent(discounts, type);
}

export function getUnitPriceWithDiscount(
  billableUnit: IBillableUnit,
  discountsApplied: IDiscount[],
  conversionFactor: number,
) {
  return conversionFactor * billableUnit.retailPrice
    * getDiscountMultiplier(discountsApplied, DiscountType.InstallerTierDiscount);
}

export function getTotalUnitsPriceWithDiscount(
  billableUnit: IBillableUnit,
  { discountsApplied, conversionFactor }: IInvoiceCost,
) {
  const unitPrice = getUnitPriceWithDiscount(billableUnit, discountsApplied, conversionFactor);
  const unitPriceRounded = roundAsCurrencyOnUI(unitPrice);
  return getTotalUnitsPrice(unitPriceRounded, billableUnit.numberOfUnits);
}

export function isSystemManager(data: InternalAppsCompany) {
  return data.typeOfCustomer === AccountType.SystemManager;
}

export interface ISiteAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postCode: string;
  countryCode: string;
}

export interface ISiteContact {
  details: string;
  phoneNumber?: string;
  diallingCode?: string;
  phoneNumber2?: string;
  email: string;
  diallingCodeCountryCode?: string;
}

export interface ISiteInfo<TSiteAddress = ISiteAddress, TSiteContact = ISiteContact> {
  id?: string;
  companyId: string;
  siteName: string;
  siteAddress: TSiteAddress;
  siteContact: TSiteContact;
  showContactInApp: boolean;
  isSiteBound: boolean;
  mobileUsersCount: number;
  registeredAppUsers: number;
}

export async function getSiteInfo(siteId?: string, companyId?: string) {
  if (!siteId || !companyId) {
    return null;
  }

  const url = Urls.Site(siteId, companyId)!;

  return httpGetJson<ISiteInfo>(url);
}

enum All {
  All = -1,
}

export const InvoiceStatusFilterOptions = {
  ...InvoiceStatus,
  ...All,
};

export type InvoiceStatusFilterOptionsType = InvoiceStatus | All;

export function useInvoiceStatusOptions() {
  const { t } = useTranslation();
  const invoiceStatusesTranslationsRef = useRef<Record<InvoiceStatusFilterOptionsType, string>>({
    [InvoiceStatusFilterOptions.All]: t('All'),
    [InvoiceStatusFilterOptions.Unknown]: t('-'),
    [InvoiceStatusFilterOptions.Unpaid]: t('InvoiceStatusDue'),
    [InvoiceStatusFilterOptions.Pending]: t('InvoiceStatusProcessing'),
    [InvoiceStatusFilterOptions.Paid]: t('InvoiceStatusPaid'),
    [InvoiceStatusFilterOptions.Failed]: t('InvoiceStatusFailed'),
    [InvoiceStatusFilterOptions.Overdue]: t('InvoiceStatusOverdue'),
  });

  const invoiceStatusesTranslations = invoiceStatusesTranslationsRef.current;
  const options = Object.keys(invoiceStatusesTranslations)
    .filter((p) => +p !== InvoiceStatusFilterOptions.Unknown)
    .map((p) => +p).sort() as InvoiceStatus[];
  // @ts-ignore
  const getOptionLabel = (option: InvoiceStatus) => invoiceStatusesTranslations[option];

  return { options, getOptionLabel };
}

