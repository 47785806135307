import { useTranslation } from 'react-i18next';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCanLeave } from '../../useAppLocation';
import { closePopup, showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import ServerAccessDeactivateConfirmationPopup from './ServerAccessDeactivateConfirmationPopup';
import ServerAccessCanLeavePopup from './ServerAccessCanLeavePopup';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { ReactComponent as ActivationCodeIcon } from '../../assets/supportServerAccess/activationCode.svg';
import { ReactComponent as UserEmailIcon } from '../../assets/supportServerAccess/email.svg';
import { ReactComponent as SiteNameIcon } from '../../assets/supportServerAccess/siteName.svg';
import { ReactComponent as RequestedByIcon } from '../../assets/supportServerAccess/requestedBy.svg';
import { ReactComponent as CopyPasswordIcon } from '../../assets/supportServerAccess/copyPassword.svg';
import { IUserCredentials } from './supportServerAccess.models';
import { useP10RemoteAccessApi } from './SupportServerAccess.api';
import AppRequestFailedPopup from '../../shared/appUIFramework/components/AppRequestFailedPopup';
import { formatMsToTime } from '../../shared/formatters/formatDate';
import AppOperationProcessingPopup from './AppOperationProcessingPopup';

function SectionHeader({ text }: { text: string }) {
  return <span className='app-font-24 app-weight-600 app-color-primary-black app-uppercase app-mb-32'>{text}</span>;
}

function RequestDetails(
  { disabled, setUserCredentials, cacheActivationCode }: {
    disabled: boolean;
    cacheActivationCode: (activationCode: string) => void;
    setUserCredentials: (userCredentials: IUserCredentials | null) => void;
  },
) {
  const { t } = useTranslation();
  const { api } = useP10RemoteAccessApi();

  const [activationCode, setActivationCode] = useState('');
  const [activationCodeErrorMessage, setActivationCodeErrorMessage] = useState('');
  const [activationCodeTouched, setActivateCodeTouched] = useState(false);
  const [email, setEmail] = useState('');
  const [siteName, setSiteName] = useState('');
  const [requestedBy, setRequestedBy] = useState('');
  const [activationCodeProcessing, setActivationCodeProcessing] = useState(false);
  const [activateProcessing, setActivateProcessing] = useState(false);

  if (!api) {
    return <></>;
  }

  const handleActivationCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setActivationCodeErrorMessage('');
    setActivationCode(e.target.value);
    setActivateCodeTouched(true);
    setEmail('');
    setSiteName('');

    if (e.target.value.length > 8) {
      setActivationCode(e.target.value.slice(0, 8));
      return;
    }

    if (e.target.value.length < 8) {
      setRequestedBy('');
      return;
    }

    if (e.target.value.length === 8) {
      setActivationCodeProcessing(true);

      try {
        const { requester, siteName } = await api.getSessionInfo(e.target.value);
        setEmail(requester);
        setSiteName(siteName);
      } catch (error) {
        setActivationCodeErrorMessage('Invalid activation code');
      }

      setActivationCodeProcessing(false);
    }
  };

  const resetRequestDetailsToInitialState = () => {
    setActivationCode('');
    setActivationCodeErrorMessage('');
    setActivateCodeTouched(false);
    setEmail('');
    setSiteName('');
    setRequestedBy('');
    setActivationCodeProcessing(false);
  };

  const resetToInitialState = () => {
    resetRequestDetailsToInitialState();
    setUserCredentials(null);
  };

  const activate = async () => {
    try {
      setActivateProcessing(true);
      showPopup({
        reactNode: (
          <AppOperationProcessingPopup titleKey='ActivateSupportServerSession' bodyKey='ActivationInProgress' />
        ),
        disableCloseOnOverlay: true,
        disableCloseOnEscape: true,
      });
      const activateResponse = await api.activateSession(activationCode);
      closePopup();
      setUserCredentials({
        password: activateResponse.password,
        username: activateResponse.username,
        expiryDateEpoch: activateResponse.expiryTime,
      });
      cacheActivationCode(activationCode);
      resetRequestDetailsToInitialState();
    } catch (error: any) {
      await showPopup(<AppRequestFailedPopup titleKey='ActivateSupportServerSession' />);
    } finally {
      setActivateProcessing(false);
    }
  };

  const activationCodeHasBeenValidated = !!email && !!siteName;
  const buttonsDisabled = activationCodeProcessing || activateProcessing || disabled || !activationCodeHasBeenValidated
    || !requestedBy;
  const fieldsDisabled = activateProcessing || activationCodeProcessing || disabled;

  return (
    <div className='app-d-flex app-flex-column'>
      <SectionHeader text={t('RequestDetails')} />
      <div className='app-form'>
        <div
          className='app-form-control app-mb-20'
          aria-invalid={activationCodeTouched && !!activationCodeErrorMessage}
        >
          <div className='app-form-control-label app-form-control-label-with-prefix'>
            <span>{t('ActivationCode')}</span>
            <span className='app-text-secondary-red-color'>&nbsp;*</span>
          </div>
          <div className='app-form-control-input'>
            <div className='app-form-control-prefix'>
              <ActivationCodeIcon />
            </div>
            <input
              value={activationCode}
              maxLength={8}
              disabled={fieldsDisabled}
              onChange={handleActivationCodeChange}
              type='text'
              className='app-form-input'
            />
          </div>
          {activationCodeProcessing && (
            <AppShowLoading
              style={{
                height: 10,
                width: 180,
                position: 'absolute',
                top: 93,
              }}
              showLoading
              inline
            />
          )}
          {activationCodeTouched && activationCodeErrorMessage && (
            <div className='app-form-error app-form-error-with-prefix'>
              {activationCodeErrorMessage}
            </div>
          )}
        </div>
        <div className='app-form-control app-mb-20'>
          <div className='app-form-control-label app-form-control-label-with-prefix'>
            <span>{t('UserEmail')}</span>
          </div>
          <div className='app-form-control-input'>
            <div className='app-form-control-prefix'>
              <UserEmailIcon />
            </div>
            <input
              value={email}
              disabled
              type='text'
              className='app-form-input'
            />
          </div>
        </div>
        <div className='app-form-control app-mb-20'>
          <div className='app-form-control-label app-form-control-label-with-prefix'>
            <span>{t('SiteName')}</span>
          </div>
          <div className='app-form-control-input'>
            <div className='app-form-control-prefix'>
              <SiteNameIcon />
            </div>
            <input
              value={siteName}
              disabled
              type='text'
              className='app-form-input'
            />
          </div>
        </div>
        <div className='app-form-control app-mb-20'>
          <div className='app-form-control-label app-form-control-label-with-prefix'>
            <span>{t('RequestedBy')}</span>
            <span className='app-text-secondary-red-color'>&nbsp;*</span>
          </div>
          <div className='app-form-control-input'>
            <div className='app-form-control-prefix'>
              <RequestedByIcon />
            </div>
            <input
              value={requestedBy}
              disabled={fieldsDisabled}
              maxLength={255}
              onChange={e => setRequestedBy(e.target.value)}
              type='text'
              className='app-form-input'
            />
          </div>
        </div>
      </div>
      <div className='app-d-flex app-justify-content-end app-gap-20'>
        <button
          className='app-secondary-button app-font-20 app-weight-600 app-uppercase app-button-disabled-special'
          onClick={resetToInitialState}
          disabled={buttonsDisabled}
        >
          {t('Cancel')}
        </button>
        <button
          className='app-primary-button app-font-20 app-weight-600 app-uppercase app-button-disabled-special'
          onClick={activate}
          disabled={buttonsDisabled}
        >
          {t('Activate')}
        </button>
      </div>
    </div>
  );
}

function CountDownTimeEpoch({ timeEpoc }: { timeEpoc: number }) {
  const [timeLeftMs, setTimeLeftMs] = useState(timeEpoc * 1000 - new Date().getTime());

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const leftMs = timeEpoc * 1000 - new Date().getTime();
      if (leftMs <= 0) {
        return;
      }
      setTimeLeftMs(leftMs);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [timeEpoc, timeLeftMs]);

  return <>{formatMsToTime(timeLeftMs)}</>;
}

function UserCredentials(
  { userCredentials, setUserCredentials, activationCode, onExpiredOrDeactivated, userCredentialsInvalid }: {
    userCredentials: IUserCredentials | null;
    userCredentialsInvalid: boolean;
    setUserCredentials: (userCredentials: IUserCredentials | null) => void;
    activationCode: string;
    onExpiredOrDeactivated: () => void;
  },
) {
  const { t } = useTranslation();
  const { api } = useP10RemoteAccessApi();
  const [isExpired, setIsExpired] = useState(
    userCredentials && userCredentials.expiryDateEpoch
    && new Date().getTime() > Number(userCredentials.expiryDateEpoch * 1000),
  );

  const [deactivated, setDeactivated] = useState(false);

  useEffect(() => {
    if(!userCredentialsInvalid) {
      setDeactivated(false);
      setIsExpired(false);
    }

  }, [userCredentials, userCredentialsInvalid]);

  useEffect(() => {
    if (isExpired || deactivated) {
      onExpiredOrDeactivated();
    }
  }, [deactivated, isExpired, onExpiredOrDeactivated]);

  const intervalIdRef = useRef<any>(null);
  useEffect(() => {
    if (userCredentials && userCredentials.expiryDateEpoch) {
      intervalIdRef.current = setInterval(() => {
        setIsExpired(new Date().getTime() > Number(userCredentials.expiryDateEpoch * 1000));
      }, 1000);

      return () => {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
      };
    }
  }, [userCredentials]);

  const [copiedUsernameVisible, setCopiedUsernameVisible] = useState(false);
  const [copiedPasswordVisible, setCopiedPasswordVisible] = useState(false);
  const copiedPasswordVisibleTimeoutRef = useRef<any>(null);
  const copiedUsernameVisibleTimeoutRef = useRef<any>(null);
  useEffect(() => {
    return () => {
      if (copiedPasswordVisibleTimeoutRef.current) {
        clearTimeout(copiedPasswordVisibleTimeoutRef.current);
      }

      if (copiedUsernameVisibleTimeoutRef.current) {
        clearTimeout(copiedUsernameVisibleTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (api) {
      return api.onExternalSessionDeactivation(() => {
        setDeactivated(true);
      });
    }
  }, [userCredentials, api, setUserCredentials]);

  if (!api) {
    return <></>;
  }

  const deactivate = async () => {
    const shouldDeactivate = await showPopup(<ServerAccessDeactivateConfirmationPopup />);
    if (shouldDeactivate) {
      try {
        setIsExpired(false);
        setDeactivated(false);
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
        showPopup({
          reactNode: (
            <AppOperationProcessingPopup titleKey='DeactivateSupportServerSession' bodyKey='DeactivationInProgress' />
          ),
          disableCloseOnOverlay: true,
          disableCloseOnEscape: true,
        });
        await api.deactivateSession(activationCode);
        closePopup();
        setUserCredentials(null);
      } catch (e) {
        console.error(e);
        await showPopup(<AppRequestFailedPopup titleKey='DeactivateSupportServerSession' />);
      }
    }
  };

  const copyPasswordToClipboard = () => {
    if (userCredentials?.password) {
      window.navigator.clipboard.writeText(userCredentials.password);
      setCopiedPasswordVisible(true);
      copiedPasswordVisibleTimeoutRef.current = setTimeout(() => {
        setCopiedPasswordVisible(false);
      }, 2000);
    }
  };

  const copyUsernameToClipboard = () => {
    if (userCredentials?.username) {
      window.navigator.clipboard.writeText(userCredentials.username);
      setCopiedUsernameVisible(true);
      copiedUsernameVisibleTimeoutRef.current = setTimeout(() => {
        setCopiedUsernameVisible(false);
      }, 2000);
    }
  };

  return (
    <div className='app-d-flex app-flex-column'>
      <SectionHeader text={t('UserCredentials')} />
      <div className='app-py-23 app-mt-37 app-px-34 app-bg-white app-mh-212 app-border-radius-12'>
        <div className='app-d-flex app-flex-column app-gap-42'>
          <div className='app-d-flex app-align-items-center'>
            <div className='app-font-20 app-w-225 app-weight-600 app-color-primary-black'>{t('Username')}</div>
            <div className='app-font-20 app-color-primary-black app-w-120 app-ellipsis app-mr-45'>
              {userCredentials?.username}
            </div>
            <div>
              {userCredentials?.password && (
                <CopyPasswordIcon className='app-cursor-pointer' onClick={copyUsernameToClipboard} />
              )}
            </div>
            {copiedUsernameVisible && (
              <div className='app-position-relative'>
                <div className='app-copied-message'>{t('Copied')}</div>
                <div className='app-copied-message-triangle' />
              </div>
            )}
          </div>
          <div className='app-d-flex app-align-items-center'>
            <div className='app-font-20 app-w-225 app-weight-600 app-color-primary-black'>{t('Password')}</div>
            <div className='app-font-20 app-color-primary-black app-w-120 app-mr-45'>
              {userCredentials?.password}
            </div>
            <div>
              {userCredentials?.password && (
                <CopyPasswordIcon className='app-cursor-pointer' onClick={copyPasswordToClipboard} />
              )}
            </div>
            {copiedPasswordVisible && (
              <div className='app-position-relative'>
                <div className='app-copied-message'>{t('Copied')}</div>
                <div className='app-copied-message-triangle' />
              </div>
            )}
          </div>
          <div className='app-d-flex'>
            <div className='app-font-20 app-w-225 app-weight-600 app-color-primary-black'>
              {t('SessionExpiry')}
            </div>
            <div
              className={`app-font-20 app-uppercase ${isExpired || deactivated ? 'app-color-secondary-red' : 'app-color-primary-black'
                }`}
            >
              {userCredentials && !isExpired && !deactivated && (
                <CountDownTimeEpoch timeEpoc={userCredentials?.expiryDateEpoch} />
              )}
              {userCredentials && isExpired && t('Expired')}
              {userCredentials && deactivated && t('Deactivated')}
            </div>
          </div>
        </div>
      </div>
      {userCredentials && !isExpired && !deactivated && (
        <div className='app-d-flex app-justify-content-end app-mt-20'>
          <button onClick={deactivate} className='app-warn-button app-font-20 app-weight-600 app-uppercase'>
            {t('Deactivate')}
          </button>
        </div>
      )}
    </div>
  );
}

export default function SupportServerAccess() {
  const { t } = useTranslation();
  const [userCredentials, setUserCredentials] = useState<IUserCredentials | null>(null);
  const [userCredentialsInvalid, setUserCredentialsInvalid] = useState(false);

  const onExpiredOrDeactivated = useCallback(() => {
    setUserCredentialsInvalid(true);
  }, []);

  const onNewUserCredentials = useCallback((newCredentials: IUserCredentials | null) => {
    setUserCredentials(newCredentials);
    setUserCredentialsInvalid(false);
  }, []);

  const [activationCode, setActivationCode] = useState('');
  const { loading } = useP10RemoteAccessApi();

  const confirmationOnLeaveRequired = userCredentials != null && !userCredentialsInvalid;

  useCanLeave(async () => {
    if (confirmationOnLeaveRequired && !await showPopup(<ServerAccessCanLeavePopup />)) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    const confirmBeforeLeave = (e: BeforeUnloadEvent) => {
      if (confirmationOnLeaveRequired) {
        e.returnValue = t('AreYouSureYouWantToLeave') + ' ' + t('AllCredentialsWillBeCleared');
      }
    };

    window.addEventListener('beforeunload', confirmBeforeLeave);

    return () => {
      window.removeEventListener('beforeunload', confirmBeforeLeave);
    };
  }, [confirmationOnLeaveRequired, t]);

  return (
    <>
      <AppContentToolbar>
        <span className='app-toolbar-breadcrump'>{t('SupportAccessRequest')}</span>
      </AppContentToolbar>
      <AppShowLoading showLoading={loading}>
        <div className='app-content'>
          <div className='app-d-flex app-gap-162 app-px-30'>
            <div className='app-w-50pcnt'>
              {
                <RequestDetails
                  cacheActivationCode={setActivationCode}
                  disabled={userCredentials != null && !userCredentialsInvalid}
                  setUserCredentials={onNewUserCredentials}
                />
              }
            </div>
            <div className='app-w-50pcnt'>
              <UserCredentials
                onExpiredOrDeactivated={onExpiredOrDeactivated}
                userCredentialsInvalid={userCredentialsInvalid}
                userCredentials={userCredentials}
                setUserCredentials={setUserCredentials}
                activationCode={activationCode}
              />
            </div>
          </div>
        </div>
      </AppShowLoading>
    </>
  );
}
