import { MutableRefObject, useEffect, useState } from 'react';

function isScrollVisible(el: HTMLElement | null) {
  if (!el) {
    return false;
  }

  // eslint-disable-next-line no-param-reassign
  el.scrollTop += 1;
  if (el.scrollTop) {
    // eslint-disable-next-line no-param-reassign
    el.scrollTop -= 1;
    return true;
  }

  return false;
}

function addOrRemoveClass(className: string, element: HTMLDivElement, shouldAdd: boolean) {
  if (shouldAdd) {
    element.classList.add(className);
  } else {
    element.classList.remove(className);
  }
}

const appTableScrollVisibleClass = 'app-table-scroll-visible';

// there is no way to check is scroll visible or not with pure CSS, so we need to manage styles with JS
export function useAppTableScrollStyles({ tableContentRef, tableHeaderRef, isTableVisible }: {
  tableContentRef: MutableRefObject<HTMLDivElement | null>;
  tableHeaderRef?: MutableRefObject<HTMLDivElement | null>;
  isTableVisible: boolean;
}) {
  const [scrollStylesApplied, setScrollStylesApplied] = useState(false);

  useEffect(() => {
    if (
      !tableContentRef.current
      || (tableHeaderRef && !tableHeaderRef.current)
      || !isTableVisible
    ) {
      return;
    }

    // timeout to check is scroll visible on the next tick, otherwise sometimes it may not be applied
    const timeoutId = setTimeout(() => {
      if(!tableContentRef.current) {
        return;
      }

      const useScrollStyles = isScrollVisible(tableContentRef.current);
      addOrRemoveClass(appTableScrollVisibleClass, tableContentRef.current, useScrollStyles);
      if (tableHeaderRef && tableHeaderRef.current) {
        addOrRemoveClass(appTableScrollVisibleClass, tableHeaderRef.current, useScrollStyles);
      }
      setScrollStylesApplied(useScrollStyles);
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [isTableVisible, tableContentRef, tableHeaderRef]);

  return scrollStylesApplied;
}
