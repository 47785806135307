import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { httpPost } from '../../shared/backend/http/http';
import {
  AppError,
  IActivationCodePayload,
  IP10NeogitateResult,
  ISessionActivatedResponse,
  ISessionDeactivatedResponse,
  ISessionInfoResponse,
  IUserCredentials,
  P10RemoteAccessHandlerMessageIds,
  P10RemoteAccessTriggerMessageIds,
} from './supportServerAccess.models';
import { useEffect, useState } from 'react';
import {
  MockActivation,
  MockDeactivation,
  MockSessionInfo,
  SupportServerAccessFeatureFlags,
  useSupportServerAccessFeatureFlags,
} from './SupportServerAccess.featureFlag';
import { resetRequestFailedPopupCount } from '../../shared/appUIFramework/components/AppRequestFailedPopup';

interface IP10RemoteAccessApi {
  getSessionInfo(activationCode: string): Promise<ISessionInfoResponse>;
  activateSession(activationCode: string): Promise<ISessionActivatedResponse>;
  deactivateSession(activationCode: string): Promise<ISessionDeactivatedResponse>;
  onExternalSessionDeactivation(handler: () => unknown): () => void;
}

class P10RemoteAccessApiModule implements IP10RemoteAccessApi {
  private static consumersCount: number = 0;
  private static Instance: P10RemoteAccessApiModule | null = null;
  private static InstancePromise: Promise<P10RemoteAccessApiModule> | null = null;
  private isAwaitingForManualSessionDeactivation: boolean = false;

  private constructor(
    private readonly connection: HubConnection,
    private readonly featureFlags: SupportServerAccessFeatureFlags,
  ) {
  }

  public static async alloc(featureFlags: SupportServerAccessFeatureFlags): Promise<P10RemoteAccessApiModule> {
    P10RemoteAccessApiModule.consumersCount += 1;
    if (P10RemoteAccessApiModule.consumersCount === 1 || !P10RemoteAccessApiModule.InstancePromise) {
      P10RemoteAccessApiModule.InstancePromise = (async () => {
        const { url, accessToken } = await P10RemoteAccessApiModule.negotiate(featureFlags.negotiateBaseUrl);
        const connection = new HubConnectionBuilder()
          .withUrl(url, { accessTokenFactory: () => accessToken })
          .configureLogging('none')
          .build();

        if (connection.state !== HubConnectionState.Connected && connection.state !== HubConnectionState.Connecting) {
          await connection.start();
        }

        P10RemoteAccessApiModule.Instance = new P10RemoteAccessApiModule(connection, featureFlags);
        return P10RemoteAccessApiModule.Instance;
      })();
    }

    return P10RemoteAccessApiModule.Instance || await P10RemoteAccessApiModule.InstancePromise;
  }

  public static async free() {
    // timeout to prevent frequent connect/disconnect, so consumers count can be increased/decreased because of rerendering
    setTimeout(async () => {
      P10RemoteAccessApiModule.consumersCount -= 1;
      if (P10RemoteAccessApiModule.consumersCount === 0 && P10RemoteAccessApiModule.Instance) {
        await P10RemoteAccessApiModule.Instance.connection.stop();
        P10RemoteAccessApiModule.Instance = null;
      }
    }, 2000);
  }

  private static async negotiate(p10ServerBaseUrl: string): Promise<IP10NeogitateResult> {
    const response = await httpPost(`${p10ServerBaseUrl}/negotiate`, {});
    const res = await response.json() as IP10NeogitateResult;
    return res;
  }

  private async getFromSignalR<TRequest, TResult>(
    triggerMethodName: P10RemoteAccessTriggerMessageIds,
    payload: TRequest,
    handlerMethodName: P10RemoteAccessHandlerMessageIds,
  ): Promise<TResult> {
    return new Promise(async (resolve, reject) => {
      try {
        const timeoutId = setTimeout(() => {
          reject(
            new AppError(`Timeout for signalr: ${JSON.stringify({ triggerMethodName, payload, handlerMethodName })}`),
          );
        }, 30000);
        if (this.connection.state !== HubConnectionState.Connected) {
          await this.connection.start();
        }
        this.connection.on(handlerMethodName, async (response: string) => {
          try {
            this.connection.off(handlerMethodName);
            clearTimeout(timeoutId);
            const result = typeof response === 'string' ? await JSON.parse(response) : response;
            if (result.result != null && result.result.toString() !== '200') {
              throw new AppError(
                `Failed to get success response for signalr: ${JSON.stringify({ triggerMethodName, payload, handlerMethodName, result })
                }`,
              );
            }
            resetRequestFailedPopupCount();
            resolve(result as TResult);
          } catch (e) {
            console.error(e);
            reject(e);
          }
        });
        await this.connection.send(triggerMethodName, payload);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  async getSessionInfo(activationCode: string): Promise<ISessionInfoResponse> {
    if (activationCode.length !== 8) {
      return Promise.reject('Invalid activation code');
    }

    if (this.featureFlags.mockSessionInfo === MockSessionInfo.Disabled) {
      return this.getFromSignalR<IActivationCodePayload, ISessionInfoResponse>(
        P10RemoteAccessTriggerMessageIds.GetSessionInfo,
        { activationCode },
        P10RemoteAccessHandlerMessageIds.SessionInfo,
      );
    } else if (this.featureFlags.mockSessionInfo === MockSessionInfo.PassWithMock) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      resetRequestFailedPopupCount();
      return Promise.resolve({
        status: 'Active',
        requester: 'John Doe',
        approvedBy: 'Jane Doe',
        lastUpdated: Date.now(),
        siteName: 'Site Name',
      });
    } else {
      return Promise.reject('Session info not found');
    }
  }

  async activateSession(activationCode: string) {
    if (this.featureFlags.mockActivation === MockActivation.Disabled) {
      return this.getFromSignalR<IActivationCodePayload, ISessionActivatedResponse>(
        P10RemoteAccessTriggerMessageIds.ActivateSession,
        { activationCode },
        P10RemoteAccessHandlerMessageIds.SessionActivated,
      );
    } else if (this.featureFlags.mockActivation === MockActivation.PassWithMock) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      resetRequestFailedPopupCount();
      return Promise.resolve({
        status: 'Active',
        requester: 'John Doe',
        approvedBy: 'Jane Doe',
        lastUpdated: Date.now(),
        username: 'PSLQSTBN',
        password: 'KKHOSXUQ',
        expiryTime: (Date.now() + 1000 * 5) / 1000,
      });
    } else if (this.featureFlags.mockActivation === MockActivation.PassWithExpiredMock) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      resetRequestFailedPopupCount();
      return Promise.resolve({
        status: 'Active',
        requester: 'John Doe',
        approvedBy: 'Jane Doe',
        lastUpdated: Date.now(),
        username: 'john.doe',
        password: 'password',
        expiryTime: Date.now() - 1000 * 60 * 60 * 24,
      });
    } else {
      return Promise.reject('Session activation failed');
    }
  }

  async deactivateSession(activationCode: string) {
    this.isAwaitingForManualSessionDeactivation = true;
    if (this.featureFlags.mockDeactivation === MockDeactivation.Disabled) {
      const res = await this.getFromSignalR<IActivationCodePayload, ISessionDeactivatedResponse>(
        P10RemoteAccessTriggerMessageIds.DeactivateSession,
        { activationCode },
        P10RemoteAccessHandlerMessageIds.SessionDeactivated,
      );
      this.isAwaitingForManualSessionDeactivation = false;

      return res;
    } else if (this.featureFlags.mockDeactivation === MockDeactivation.PassWithMock) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      resetRequestFailedPopupCount();
      this.isAwaitingForManualSessionDeactivation = false;
      return Promise.resolve({
        status: 'Inactive',
        requester: 'John Doe',
        approvedBy: 'Jane Doe',
        lastUpdated: Date.now(),
      });
    } else {
      this.isAwaitingForManualSessionDeactivation = false;
      return Promise.reject('Session deactivation failed');
    }
  }

  onExternalSessionDeactivation(handler: () => unknown): () => void {
    this.connection.on(P10RemoteAccessHandlerMessageIds.SessionDeactivated, async () => {
      if (!this.isAwaitingForManualSessionDeactivation) {
        handler();
      }
    });

    return () => {
      this.connection.off(P10RemoteAccessHandlerMessageIds.SessionDeactivated);
    };
  }
}

export function useP10RemoteAccessApi(): { api: IP10RemoteAccessApi | null, loading: boolean } {
  const [api, setApi] = useState<P10RemoteAccessApiModule | null>(null);
  const { featureFlags } = useSupportServerAccessFeatureFlags();

  useEffect(() => {
    P10RemoteAccessApiModule.alloc(featureFlags).then(setApi);
    return () => {
      P10RemoteAccessApiModule.free();
    };
  }, [featureFlags]);

  return { api, loading: api == null };
}

export async function getUserCredentials(activationCode: string) {
  if (activationCode.length !== 8) {
    return Promise.reject('Invalid activation code');
  }

  return Promise.resolve({
    username: 'john.doe',
    password: 'password',
    expiryDateEpoch: Date.now() + 1000 * 60 * 60 * 24,
  } as IUserCredentials);
}
