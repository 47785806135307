import {
  WEBSOCKET_RESPONSE_CODE_ERROR_LIST,
  WEBSOCKET_TIMEOUT_MESSAGE_CODE,
} from '../responseCodes';
import { TimeoutError } from '../errors/timeoutError';
import { httpGet, httpGetJson, httpPut } from '../http/http';

interface INegotiate {
    baseUrl: string;
    url: string;
    accessToken: string;
}

export async function wsSubscribe<TMessageModel>(onDataReceived: (data: TMessageModel) => any, getNegotiateUrl: () => Promise<string>, negotiateGroupsUrl?: string): Promise<() => void> {
  const url = await getNegotiateUrl();
  const webSocket = new WebSocket(url);

  const notifyAboutMessage = (e: any) => {
    console.log(e.data);
    const data = JSON.parse(e.data);
    onDataReceived(data);
  };
  webSocket.addEventListener('message', notifyAboutMessage);

  const notifyAboutError = () => {
    console.log('WS error')
  };
  webSocket.addEventListener('error', notifyAboutError);

  const joinUserToGroups = async () => {
    if (negotiateGroupsUrl) {
      try {
        await httpPut(negotiateGroupsUrl, {});
      } catch (e) {
        console.log(e);
      }
    }
  };
  webSocket.addEventListener('open', joinUserToGroups);

  return () => {
    webSocket.removeEventListener('message', notifyAboutMessage);
    webSocket.removeEventListener('open', joinUserToGroups);
    webSocket.removeEventListener('error', notifyAboutError);
    webSocket.close();
  };
}

export async function wsGet<TResult>(triggerForWebSocket: string | (() => Promise<void>), negotiateUrl: string): Promise<TResult> {
  const { url } = await httpGetJson<INegotiate>(negotiateUrl);

  return new Promise((resolve, reject) => {
    const webSocket = new WebSocket(url);

    const dispose = () => {
      webSocket.close();
    };

    webSocket.addEventListener('open', async () => {
      try {
        if (typeof triggerForWebSocket === 'function') {
          await triggerForWebSocket();
        } else {
          await httpGet(triggerForWebSocket);
        }
      } catch (error) {
        dispose();
        reject(error);
      }
    });

    webSocket.addEventListener('message', (e: any) => {
      dispose();
      const messageData = JSON.parse(e.data);

      if (WEBSOCKET_RESPONSE_CODE_ERROR_LIST.includes(messageData.code)) {
        const error = messageData.code === WEBSOCKET_TIMEOUT_MESSAGE_CODE ? new TimeoutError(e) : new Error(e);
        reject(error);
      } else {
        resolve(messageData);
      }
    });

    webSocket.addEventListener('error', (e) => {
      dispose();
      reject(e);
    });
  });
}
