import { useTranslation } from 'react-i18next';
import MonitorsIcon from '../../../../assets/icons/home page icons/Monitors.svg';
import PanelsIcon from '../../../../assets/icons/home page icons/Panels.svg';
import AppAnalyticsCountIcon from './AppAnalyticsCountIcon';
import AppAnalyticsSmallIcon from './AppAnalyticsSmallIcon';
import { ReactComponent as SystemsIcon } from '../../../../assets/icons/home page icons/Systems.svg';
import { useTotalCountWithDelta } from '../../../../shared/appBackend/useMetrics';
import { useContext } from 'react';
import { EntryAnalyticsContext } from '../EntryAnalyticsContext';

function Sites() {
  const { t } = useTranslation();
  const { totalCount, totalCountDelta, isDeltaRequired  } = useTotalCountWithDelta();

  return (
    <>
      <AppAnalyticsSmallIcon
        icon={<img src={MonitorsIcon} alt={'Icon'} />}
        count={totalCount?.monitorsCount}
        isDeltaRequired={isDeltaRequired}
        delta={totalCountDelta?.monitorsCount}
        title={t('Monitors')}
      />
      <AppAnalyticsSmallIcon
        icon={<img src={PanelsIcon} alt={'Icon'} />}
        count={totalCount?.panelsCount}
        isDeltaRequired={isDeltaRequired}
        delta={totalCountDelta?.panelsCount}
        title={t('Panels')}
      />
    </>
  );
}

export default function SitesWidget() {
  const { t } = useTranslation();

  const { totalCount, totalCountDelta, isDeltaRequired } = useTotalCountWithDelta();
  const { site } = useContext(EntryAnalyticsContext);

  return (
    <>
      {!site && (
        <div className='app-home-widget app-analytics-widget'>
          <div className='app-home-widget-left'>
            <div className='app-home-widget-title-text'>
              {t('Sites')}
            </div>
            <div className='app-analytics-widget-big-icon-container'>
              <div className='app-analytics-widget-big-icon app-analytics-widget-systems-big-icon'>
                <AppAnalyticsCountIcon 
                  icon={<SystemsIcon />}
                  count={totalCount?.sitesCount}
                  isDeltaRequired={isDeltaRequired}
                  delta={totalCountDelta?.sitesCount}
                  title={t('Sites')} />
              </div>
            </div>
          </div>
          <div className='app-home-widget-right'>
            <div style={{ visibility: 'hidden' }}>Spacer</div>
            <div className='app-analytics-widget-body-small-icons'>
              <Sites />
            </div>
            <div></div>
          </div>
        </div>
      )}
      {site && (
        <div className='app-home-widget app-analytics-widget app-flex-wrap app-max-width-360'>
          <div className='app-home-widget-title-text app-w-100pcnt'>
            {t('Site')}
          </div>
          <div className='app-analytics-widget-body-small-icons app-w-fit-content app-h-32pcnt'>
            <Sites />
          </div>
        </div>
      )}
    </>
  );
}
