import { useTranslation } from 'react-i18next';
import MaintenanceEmailsShell from './MaintenanceEmailsShell';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search icon.svg';
import { useRef, useState } from 'react';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { SortStringOptions, getSortOrderFromString, getSortOrderString, useSortStringOptions } from '../../shared/appUIFramework/hooks/useSortStringOptions';
import { PAGE_SIZE, useInfiniteWithPagination } from '../../shared/appBackend/useInfiniteWithPagination';
import { getUrlWithQueryParams } from '../../shared/backend/http/http';
import { Urls } from '../../shared/backend/urls';
import {
  IEmailLogFilter,
  IEmailLogItem,
  IEmailLogSort,
  useEmailLogCount,
} from '../../shared/appBackend/useEmailLog';
import { useAppTableScrollStyles } from '../../styles';
import './MaintenanceEmailsEmailLog.scss';
import AppTablePagination from '../home/components/AppTablePagination';
import { Formats, formatDate } from '../../shared/formatters/formatDate';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import { AppSortIcon } from '../../shared/appUIFramework/components/SortAndFilterIcons';

function useEmailLogUrl() {
  const [queryParams, setQueryParams] = useState<IEmailLogSort & IEmailLogFilter>({
    search: '',
  });

  const setDateSentSort = (status: SortStringOptions) =>
    setQueryParams(prev => ({ ...prev, dateSentSort: getSortOrderFromString(status), scheduledForSort: undefined }));
  const setScheduledDateSort = (status: SortStringOptions) =>
    setQueryParams(prev => ({ ...prev, scheduledForSort: getSortOrderFromString(status), dateSentSort: undefined }));

  const setSearch = (search: string) => setQueryParams(prev => ({ ...prev, search }));
  const url = getUrlWithQueryParams(Urls.EmailLog, queryParams);

  return {
    url,
    dateSentSort: getSortOrderString(queryParams.dateSentSort),
    setDateSentSort,
    scheduledDateSort: getSortOrderString(queryParams.scheduledForSort),
    setScheduledDateSort,
    search: queryParams.search,
    setSearch,
    filter: {
      search: queryParams.search,
    } as IEmailLogFilter,
  };
}

function useEmailLogTable(url: string, emailLogFilter: IEmailLogFilter) {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const emailLogCount = useEmailLogCount({ ...emailLogFilter });
  const maxPage = emailLogCount == null ? undefined : Math.ceil(emailLogCount / PAGE_SIZE);
  const { items, page, reset, setPage, loading } = useInfiniteWithPagination<IEmailLogItem>(
    url,
    maxPage,
    { scrollRef: tableRef, adjustPage: true },
  );
  const isScrollVisible = useAppTableScrollStyles({
    tableContentRef: tableRef,
    isTableVisible: items.length > 0,
  });

  return {
    items,
    tableRef,
    page,
    reset,
    setPage,
    maxPage,
    isScrollVisible,
    loading,
  };
}

export default function MaintenanceEmailsLog() {
  const { t } = useTranslation();
  const {
    url,
    search,
    setSearch,
    filter,
    dateSentSort,
    setDateSentSort,
    scheduledDateSort,
    setScheduledDateSort,
  } = useEmailLogUrl();

  const {
    items,
    tableRef,
    page,
    reset,
    setPage,
    maxPage,
    isScrollVisible,
    loading,
  } = useEmailLogTable(url, filter);

  const { options: stringSortOptions, getOptionLabel: getStringSortOptionLabel } = useSortStringOptions();

  return (
    <MaintenanceEmailsShell activeTab='email-log'>
      <div className='app-form-control app-form-control-search app-mw-500 app-mt-33'>
        <div className='app-form-control-input app-no-ml'>
          <div className='app-form-control-search-icon'>
            <SearchIcon />
          </div>
          <input
            type='text'
            placeholder={ t('SearchEmails') }
            value={ search }
            className='app-transfer-list-search'
            onChange={ event => {
              setSearch(event.target.value);
              reset();
            } }
          />
        </div>
      </div>
      <AppShowLoading showLoading={ loading }>
        <div className='app-table app-table-content app-table-billing-white-list app-gap-20'>
          <div className={ `app-email-log-columns app-table-header-row ${isScrollVisible ? 'app-pr-57' : ''}` }>
            <div className='app-uppercase'>{ t('EmailType') }</div>
            <div className='app-uppercase'>{ t('Author') }</div>
            <div className='app-table-header-row-with-sort'>
              <span className='app-uppercase'>{ t('DateSent') }</span>
              <AppPopupMenu
                options={ (stringSortOptions as unknown) as string[] }
                selectedOption={ dateSentSort }
                onOptionSelected={ option => {
                  reset();
                  setDateSentSort(option);
                } }
                getOptionLabel={ getStringSortOptionLabel as any }
                render={ () => <AppSortIcon applied={ !!dateSentSort } /> }
              />
            </div>
            <div className='app-table-header-row-with-sort'>
              <span className='app-uppercase'>{ t('ScheduledFor') }</span>
              <AppPopupMenu
                options={ (stringSortOptions as unknown) as string[] }
                selectedOption={ scheduledDateSort }
                onOptionSelected={ option => {
                  reset();
                  setScheduledDateSort(option);
                } }
                getOptionLabel={ getStringSortOptionLabel as any }
                render={ () => <AppSortIcon applied={ !!scheduledDateSort } /> }
              />
            </div>
          </div>
          <div
            className={ `app-flex-vertical-scrollable app-gap-20 app-d-flex app-flex-column ${
              isScrollVisible ? 'app-pr-20' : ''
            }` }
            ref={ tableRef }
          >
            { items.map(item => (
              <div
                key={ item.id }
                data-hover-disabled='true'
                className='app-email-log-columns app-table-content-row'
              >
                <div>{ item.emailType }</div>
                <div>{ item.name }</div>
                <div>{ formatDate(item.dateSent, Formats.DayMonthYearTime12) }</div>
                <div>{ formatDate(item.scheduledFor, Formats.DayMonthYearTime12) }</div>
              </div>
            )) }
          </div>
        </div>
        <div className='app-content-footer-pagination app-mt-15 app-flex-grow-1 app-d-flex app-align-items-center app-justify-content-center'>
          { items.length > 0 && isScrollVisible && (
            <AppTablePagination
              totalPages={ maxPage || 0 }
              activePageIndex={ page }
              onPageSelect={ pageIndex => setPage(pageIndex + 1) }
            />
          ) }
        </div>
      </AppShowLoading>
    </MaintenanceEmailsShell>
  );
}
