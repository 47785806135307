import { useCallback, useEffect, useState } from "react";
import { getUrlWithQueryParams, httpGetJson } from "../backend/http/http";
import { handleHttpRequestError } from "./swr/useSWRAndHandleErrors";

export interface IRefreshItemsParams {
  search?: string;
  isBillingDisabled?: boolean;
}

export interface ILazyLoadResult<TItem> {
  items: TItem[],
  loadMore: () => void,
  loading: boolean,
  lastPageLoaded: boolean,
  reset: () => void
}

const INITIAL_PAGE = 1;
const INITIAL_ITEMS = undefined;
const PAGE_SIZE = 10;

export function useLazyLoad<TItem>(url?: string, refreshItemsParams?: IRefreshItemsParams, params?: {initialPage?: number}): ILazyLoadResult<TItem> {
  const [items, setItems] = useState<TItem[] | undefined>(INITIAL_ITEMS);
  const [loading, setLoading] = useState(false);
  const [lastPageLoaded, setLastPageLoaded] = useState(false);
  const [nextPageRequested, setNextPageRequested] = useState(false);
  const [firstPageLoaded, setFirstPageLoaded] = useState(false);
  const initialPage = params?.initialPage != null ? params.initialPage : INITIAL_PAGE;
  const [currPage, setCurrPage] = useState(initialPage);

  const fetchMoreItems = useCallback(async () => {
    if (!url || loading) {
      return;
    }
    setLoading(true);
    try {
      const newItems = await httpGetJson<TItem[]>(getUrlWithQueryParams(url, {
        ...refreshItemsParams,
        page: currPage,
        pageSize: PAGE_SIZE
      }));
      if (newItems.length === 0) {
        setLastPageLoaded(true);
        return;
      }
      setItems([...(items || []), ...newItems]);
      setCurrPage(currPage + 1);
    } catch (e) {
      handleHttpRequestError(e);
    } finally {
      setLoading(false);
    }
  }, [url, currPage, items, refreshItemsParams, loading]);

  const reset = useCallback(() => {
    setItems(INITIAL_ITEMS);
    setCurrPage(initialPage);
    setLastPageLoaded(false);
    setFirstPageLoaded(false);
  }, [initialPage]);

  // reset items when any of depencies that affects the final result changed
  useEffect(() => {
    reset();
  }, [url, refreshItemsParams?.search, reset]);

  // load more when next page requested in debounced manner
  useEffect(() => {
    if (nextPageRequested) {
      const timeout = setTimeout(() => {
        setNextPageRequested(false);
        fetchMoreItems();
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      return () => {};
    }
  }, [nextPageRequested, fetchMoreItems]);

  // load first page when no pages loaded yet
  useEffect(() => {
    if (!firstPageLoaded && url) {
      setNextPageRequested(true);
      setFirstPageLoaded(true);
    }
  }, [firstPageLoaded, fetchMoreItems, url]);

  return {
    items: items || [],
    loadMore: () => setNextPageRequested(true),
    loading: loading || nextPageRequested,
    lastPageLoaded,
    reset
  };
}

