import {useTranslation} from "react-i18next";
import EntryMonitor from '../assets/Entry Monitor sign in image.png';
import PaxtonLogoSvg from '../assets/MyPaxton Logo.svg';
import {ReactComponent as SignUpTopLeftLogo} from '../assets/Paxton logo - White.svg';
import { useAppMsal } from "../shared/backend/auth/auth.msal";

export default function WelcomePage() {
    const {t} = useTranslation();
    const msal = useAppMsal();

    return (
        <>
            <div className="app-d-flex app-welcome">
                <div className="app-signup-top-left-logo">
                    <SignUpTopLeftLogo/>
                </div>
                <img
                    className="app-w-50pcnt app-h-100vh app-fit-cover"
                    src={EntryMonitor}
                    alt="login"
                />
                <div
                    className="app-d-flex app-flex-column app-align-items-center app-justify-content-center app-w-50pcnt">
                    <div className="app-mb-86 app-welcome-to">{t('Welcome_Welcome')}</div>
                    <img
                        className="app-mb-100 app-welcome-logo"
                        src={PaxtonLogoSvg}
                        alt="logo"
                    />
                    <button
                        type="button"
                        onClick={msal.signIn.bind(msal)}
                        className="app-primary-button app-mw-373"
                    >
                        {t('Welcome_SignInInstaller')}
                    </button>
                </div>
            </div>
        </>
    );
}
