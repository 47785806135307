import { CSSProperties, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaxtonLogoPng from '../../assets/MyPaxton Logo.svg';
import { ReactComponent as PaxtonBackgroundSVG } from '../../assets/modal-background.svg';
import {
  DiscountType,
  IInvoiceEntry,
  VatEvidenceType,
  getDiscountPercent,
  getSiteInfo,
  getTotalUnitsPriceWithDiscount,
  getUnitPriceWithDiscount,
  isSystemManager,
  isTaxMethodUsedInCompany,
  useCurrencyOptions,
  useInternalAppsCompanyDetails,
} from '../../shared/appBackend/useInvoices';
import { useCompanyByCustomerReference } from '../../shared/appBackend/useCompanies';
import { Formats, formatDate } from '../../shared/formatters/formatDate';
import { formatMoney } from '../../shared/formatters/formatMoney';
import { formatPercents } from '../../shared/formatters/formatPercents';

export const PDF_PREVIEW_WIDTH = 1344;
export const PDF_PREVIEW_HEIGHT = 1800;

export default function InvoicePdfPreview({
  invoice,
  offscreen,
  zoom,
}: {
  invoice: IInvoiceEntry;
  offscreen?: boolean;
  zoom?: number;
}) {
  const { t } = useTranslation();

  const style: CSSProperties = offscreen
    ? {
      position: 'absolute',
      top: -9999,
      left: 0,
      width: PDF_PREVIEW_WIDTH,
      height: PDF_PREVIEW_HEIGHT,
      overflow: 'clip',
    }
    : {
      zoom,
    };

  const { getOptionLabel: getCurrencyTranslation } = useCurrencyOptions();
  const { data: internalAppsCompany } = useInternalAppsCompanyDetails(invoice.customerReference);
  const doesCompanyNeedVatNumber = !!internalAppsCompany
    && isTaxMethodUsedInCompany(internalAppsCompany, VatEvidenceType.VatNo);
  const { company } = useCompanyByCustomerReference(invoice.customerReference);

  const [billableUnitDescriptionMap, setBillableUnitDescriptionMap] = useState<Record<string, string>>({})
  const initBillableUnitDescriptionMap = useCallback(async (invoice: IInvoiceEntry) => {
    const resultMap: Record<string, string> = {};
    for (const item of invoice.billableUnits) {
      if (!resultMap[item.billableUnitUniqueId]) {
        const siteInfo = await getSiteInfo(item.billableUnitUniqueId);
        resultMap[item.billableUnitUniqueId] = siteInfo?.siteName ?? item.description;
      }
    }
    setBillableUnitDescriptionMap(resultMap);
  }, []);

  useEffect(() => {
    if (invoice) {
      initBillableUnitDescriptionMap(invoice);
    }
  }, [invoice, initBillableUnitDescriptionMap]);

  return (
    <div className='app-invoice-pdf-html-preview' id='invoicePdfHtmlPreview' style={style}>
      {offscreen && (
        <div className='app-invoice-background-image'>
          <PaxtonBackgroundSVG />
        </div>
      )}
      <div>
        <div className={`app-invoice-pdf-html-preview-header ${offscreen ? 'invoice-pdf-offscreen' : ''}`}>
          <img src={PaxtonLogoPng} alt='logo' />
          <div>
            <span>{t('Invoice')}</span>
          </div>
        </div>
        <div className='app-invoice-pdf-html-preview-details'>
          <div className='app-invoice-pdf-html-preview-details-left'>
            <span className='app-invoice-pdf-html-to'>{t('InvoiceTo')}</span>
            {invoice.invoiceAddress.companyName && (
              <span className='app-invoice-pdf-html-preview-details-left-row'>{invoice.invoiceAddress.companyName}</span>
            )}
            {invoice.invoiceAddress.address1 && (
              <span className='app-invoice-pdf-html-preview-details-left-row'>{invoice.invoiceAddress.address1}</span>
            )}
            {invoice.invoiceAddress.address2 && (
              <span className='app-invoice-pdf-html-preview-details-left-row'>{invoice.invoiceAddress.address2}</span>
            )}
            {invoice.invoiceAddress.townCity && (
              <span className='app-invoice-pdf-html-preview-details-left-row'>{invoice.invoiceAddress.townCity}</span>
            )}
            {invoice.invoiceAddress.postcode && (
              <span className='app-invoice-pdf-html-preview-details-left-row'>{invoice.invoiceAddress.postcode}</span>
            )}
          </div>
          <div className='app-invoice-pdf-html-preview-details-right'>
            <div className='app-invoice-pdf-html-preview-row'>
              <span className='app-invoice-pdf-html-preview-label'>
                {t('InvoiceNoLong')}
                :
              </span>
              <span className='app-invoice-pdf-html-preview-value'>
                {invoice.invoiceId}
              </span>
            </div>
            {doesCompanyNeedVatNumber
              && (
                <div className='app-invoice-pdf-html-preview-row'>
                  <span className='app-invoice-pdf-html-preview-label'>
                    {t('CustomerVatNumber')}
                    :
                  </span>
                  <span className='app-invoice-pdf-html-preview-value'>
                    {company?.vatNumber}
                  </span>
                </div>
              )}
            <div className='app-invoice-pdf-html-preview-row'>
              <span className='app-invoice-pdf-html-preview-value'>
                {t('InvoiceDate')}
                :
              </span>
              <span className='app-invoice-pdf-html-preview-value'>
                {formatDate(invoice.invoiceDate, Formats.LongDate)}
              </span>
            </div>
            <div className='app-invoice-pdf-html-preview-row'>
              <span className='app-invoice-pdf-html-preview-value'>
                {t('AccountReference')}
                :
              </span>
              <span className='app-invoice-pdf-html-preview-value'>
                {internalAppsCompany?.branchOfficeId}
              </span>
            </div>
          </div>
        </div>
        <div className='app-invoice-pdf-html-preview-table'>
          <div className='app-invoice-pdf-html-preview-table-header'>
            <div className='app-invoice-pdf-html-preview-table-header-row'>
              <div className='app-invoice-pdf-html-preview-table-header-row-cell'>
                {t('Description')}
              </div>
              <div className='app-invoice-pdf-html-preview-table-header-row-cell'>
                {t('Quantity')}
              </div>
              <div className='app-invoice-pdf-html-preview-table-header-row-cell'>
                {t('UnitPrice')}
                &nbsp;(
                {getCurrencyTranslation(invoice.currencyCodeId)}
                )
              </div>
              <div className='app-invoice-pdf-html-preview-table-header-row-cell'>
                {t('Total')}
                &nbsp;(
                {getCurrencyTranslation(invoice.currencyCodeId)}
                )
              </div>
            </div>
          </div>
          <div className='app-invoice-pdf-html-preview-table-body'>
            {invoice.billableUnits.map(item => (
              <Fragment key={item.description}>
                <div className='app-invoice-pdf-html-preview-table-body-row' role='row'>
                  <div className='app-invoice-pdf-html-preview-table-body-row-cell'>
                    <div className='app-weight-600 app-mb-17'>
                      {billableUnitDescriptionMap[item.billableUnitUniqueId]}
                    </div>
                    <div className='app-invoice-pdf-html-preview-table-unit-item'>
                      {t('Home_TotalDevices_EntryAppUsers')}
                    </div>
                  </div>
                  <div className='app-invoice-pdf-html-preview-table-body-row-cell'>
                    {item.numberOfUnits}
                  </div>
                  <div className='app-invoice-pdf-html-preview-table-body-row-cell'>
                    {formatMoney(
                      invoice.currencyCodeId,
                      getUnitPriceWithDiscount(item, invoice.discountsApplied, invoice.conversionFactor),
                    )}
                  </div>
                  <div className='app-invoice-pdf-html-preview-table-body-row-cell app-weight-600'>
                    {formatMoney(invoice.currencyCodeId, getTotalUnitsPriceWithDiscount(item, invoice))}
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
          <div className='app-invoice-pdf-html-preview-table-footer' id='invoicePdfHtmlPreviewTableFooter'>
            {!!invoice.Totals.TotalDiscount && (
              <div className='app-invoice-pdf-html-preview-table-footer-row'>
                <div className='app-invoice-pdf-html-preview-table-footer-row-label'>
                  {t('DiscretionaryDiscount')}
                  &nbsp;@&nbsp;
                  {formatPercents(
                    getDiscountPercent(invoice.discountsApplied, DiscountType.DiscretionaryDiscount) * 100,
                  )}
                </div>
                <div className='app-invoice-pdf-html-preview-table-footer-row-value'>
                  -
                  {formatMoney(invoice.currencyCodeId, invoice.Totals.TotalDiscount)}
                </div>
              </div>
            )}
            <div className='app-invoice-pdf-html-preview-table-footer-row'>
              <div className='app-invoice-pdf-html-preview-table-footer-row-label'>
                {t('SubTotal')}
              </div>
              <div className='app-invoice-pdf-html-preview-table-footer-row-value'>
                {formatMoney(invoice.currencyCodeId, invoice.Totals.Subtotal)}
              </div>
            </div>
            <div className='app-invoice-pdf-html-preview-table-footer-row'>
              <div className='app-invoice-pdf-html-preview-table-footer-row-label'>
                {t('Vat')}
                &nbsp;@&nbsp;
                {formatPercents(invoice.vatPercentage * 100)}
              </div>
              <div className='app-invoice-pdf-html-preview-table-footer-row-value'>
                {formatMoney(invoice.currencyCodeId, invoice.Totals.Vat)}
              </div>
            </div>
            <div className='app-invoice-pdf-html-preview-table-footer-row'>
              <div className='app-invoice-pdf-html-preview-table-footer-row-label'>
                {t('Currency')}
              </div>
              <div className='app-invoice-pdf-html-preview-table-footer-row-value'>
                {getCurrencyTranslation(invoice.currencyCodeId)}
              </div>
            </div>
            <div className='app-invoice-pdf-html-preview-table-footer-row app-invoice-pdf-html-preview-table-footer-row-final'>
              <div className='app-invoice-pdf-html-preview-table-footer-row-label'>
                {t('Total')}
              </div>
              <div className='app-invoice-pdf-html-preview-table-footer-row-value'>
                {formatMoney(invoice.currencyCodeId, invoice.Totals.Total)}
              </div>
            </div>
          </div>
        </div>
        <div className='app-invoice-pdf-html-preview-terms'>
          <span>
            {internalAppsCompany && isSystemManager(internalAppsCompany)
              ? t('PaymentTermsInAdvance')
              : t('PaymentTermsDays', { days: 30 })}
          </span>
          <span>
            {t('YouCanPayInvoice')}
          </span>
        </div>
        <div className='app-invoice-pdf-html-preview-footer'>
          <span>{t('PaxtonAccessLtd')}</span>
          <span>{t('PaxtonAccessTel')}</span>
          <span>{t('PaxtonVatNumber')}</span>
        </div>
      </div>
    </div>
  );
}
