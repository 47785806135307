import SortActive from '../../../assets/icons/Controls/Sort icon green.svg';
import SortInactive from '../../../assets/icons/Controls/Sort icon grey.svg';
import FilterActive from '../../../assets/icons/Controls/Filter icon Green.svg';
import FilterInactive from '../../../assets/icons/Controls/Filter icon grey.svg';

interface IStateIconProps {
    applied: boolean;
}

function SortActiveIcon() {
  return (
    <img src={SortActive} alt="Sort" />
  );
}

function SortInactiveIcon() {
  return (
    <img src={SortInactive} alt="Sort" />
  );
}

function FilterActiveIcon() {
  return (
    <img src={FilterActive} alt="Filter" />
  );
}

function FilterInactiveIcon() {
  return (
    <img src={FilterInactive} alt="Filter" />
  );
}

export function AppSortIcon({
  applied,
}: IStateIconProps) {
  return (
    <>{applied ? <SortActiveIcon /> : <SortInactiveIcon />}</>
  );
}

export function AppFilterIcon({
  applied,
}: IStateIconProps) {
  return (
    <>{applied ? <FilterActiveIcon /> : <FilterInactiveIcon />}</>
  );
}
