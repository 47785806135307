/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CompanyAccountStatus,
  ICompaniesFilter,
  ICompaniesSort,
  ICompany,
  unblockCompanies,
  useCompaniesLength,
} from '../../shared/appBackend/useCompanies';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search icon.svg';
import SelectIcon from '../../assets/icons/Controls/Select icon.svg';
import SelectedIcon from '../../assets/icons/Controls/Selected icon.svg';
import { PAGE_SIZE, useInfiniteWithPagination } from '../../shared/appBackend/useInfiniteWithPagination';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { getUrlWithQueryParams } from '../../shared/backend/http/http';
import { Urls } from '../../shared/backend/urls';
import HomeShell from './components/HomeShell';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppTableScrollStyles } from '../../styles';
import AppTablePagination from './components/AppTablePagination';
import './BlockedAccounts.scss';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import { SortStringOptions, useSortStringOptions } from '../../shared/appUIFramework/hooks/useSortStringOptions';
import { AppSortIcon } from '../../shared/appUIFramework/components/SortAndFilterIcons';
import { formatCompanyAddress } from '../../shared/formatters/formatCompanyAddress';

function useCompaniesUrl() {
  const [queryParams, setQueryParams] = useState<ICompaniesFilter & ICompaniesSort>({
    search: '',
    excludeE2ECompanies: false,
    isBillingDisabled: false,
    statusSort: SortStringOptions.AtoZ,
    companyNameSort: undefined,
    isBlocked: true,
  });
  const setStatusSort = (status: SortStringOptions) =>
    setQueryParams(prev => ({ ...prev, statusSort: status, companyNameSort: undefined }));
  const setCompanyNameSort = (companyName: SortStringOptions) =>
    setQueryParams(prev => ({ ...prev, companyNameSort: companyName, statusSort: undefined }));
  const setSearch = (search: string) => setQueryParams(prev => ({ ...prev, search }));
  const companiesUrl = getUrlWithQueryParams(Urls.Companies, queryParams);

  return {
    companiesUrl,
    statusSort: queryParams.statusSort,
    setStatusSort,
    companyNameSort: queryParams.companyNameSort,
    setCompanyNameSort,
    search: queryParams.search,
    setSearch,
    companiesFilter: {
      search: queryParams.search,
      excludeE2ECompanies: queryParams.excludeE2ECompanies,
      isBillingDisabled: queryParams.isBillingDisabled,
      isBlocked: queryParams.isBlocked,
    } as ICompaniesFilter
  };
}

function useCompaniesUnblockFunctions() {
  const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);
  const buttonsDisabled = selectedCompanyIds.length === 0;
  const isCompanySelected = (companyId: string) => selectedCompanyIds.includes(companyId);
  const setCompanySelected = (companyId: string, selected: boolean) => {
    setSelectedCompanyIds(prev =>
      selected
        ? [...prev, companyId]
        : prev.filter(p => p !== companyId)
    );
  };

  const cancelSelection = () => setSelectedCompanyIds([]);
  const unblockAccounts = async () => {
    await unblockCompanies(selectedCompanyIds);
    setSelectedCompanyIds([]);
  };

  return {
    selectedCompanyIds,
    buttonsDisabled,
    isCompanySelected,
    setCompanySelected,
    cancelSelection,
    unblockAccounts,
  };
}

function AccountStatusLabel({ accountStatus }: { accountStatus: CompanyAccountStatus }) {
  const { t } = useTranslation();
  const status = {
    [CompanyAccountStatus.Ok]: t('Paid'),
    [CompanyAccountStatus.Limited]: t('Unpaid'),
    [CompanyAccountStatus.Blocked]: t('Unpaid'),
    [CompanyAccountStatus.Unknown]: t('Unpaid'),
  }[accountStatus];

  const statusClass = status === t('Paid') ? 'app-company-account-status-paid' : 'app-company-account-status-unpaid';

  return <span className={`app-company-account-status ${statusClass}`}>{status}</span>;
}

function ToggleIcon({ checked, setChecked }: { checked: boolean, setChecked: (value: boolean) => void }) {
  const imgSrc = checked ? SelectedIcon : SelectIcon;
  const toggleChecked = () => setChecked(!checked);
  return (
    <div className='app-h-30 app-cursor-pointer'>
      <img src={imgSrc} alt='Select' onClick={toggleChecked} />
    </div>
  );
}

function useCompaniesTable(companiesUrl: string, companiesFilter: ICompaniesFilter) {
  const companiesRowsRef = useRef<HTMLDivElement | null>(null);
  const { companiesLength } = useCompaniesLength({ ...companiesFilter });
  const maxCompanyPage = Math.ceil(companiesLength / PAGE_SIZE);
  const { items: companies, page, reset, setPage, loading } = useInfiniteWithPagination<ICompany>(
    companiesUrl,
    maxCompanyPage,
  );
  const isScrollVisible = useAppTableScrollStyles({
    tableContentRef: companiesRowsRef,
    isTableVisible: companies.length > 0,
  });

  return {
    companies,
    companiesRowsRef,
    page,
    reset,
    setPage,
    maxCompanyPage,
    isScrollVisible,
    loading,
  };
}

export default function BlockedAccounts() {
  const {
    companiesUrl,
    statusSort,
    setStatusSort,
    companyNameSort,
    setCompanyNameSort,
    search,
    setSearch,
    companiesFilter
  } = useCompaniesUrl();

  const {
    companies,
    companiesRowsRef,
    page,
    reset,
    setPage,
    maxCompanyPage,
    isScrollVisible,
    loading,
  } = useCompaniesTable(companiesUrl, companiesFilter);

  const {
    buttonsDisabled,
    isCompanySelected,
    setCompanySelected,
    cancelSelection,
    unblockAccounts,
  } = useCompaniesUnblockFunctions();

  const { options: stringSortOptions, getOptionLabel: getStringSortOptionLabel } = useSortStringOptions();

  const { t } = useTranslation();

  return (
    <HomeShell activeTab='blockedAccounts'>
      <AppShowLoading showLoading={loading}>
        <div className='app-d-flex app-justify-content-between app-align-items-center'>
          <div className='app-form-control app-form-control-search app-mw-500'>
            <div className='app-form-control-input app-no-ml'>
              <div className='app-form-control-search-icon'>
                <SearchIcon />
              </div>
              <input
                type='text'
                placeholder={t('SearchCompanies')}
                value={search}
                className='app-transfer-list-search'
                onChange={event => {
                  setSearch(event.target.value)
                  reset();
                }}
              />
            </div>
          </div>
          <div className='app-d-flex app-justify-content-between app-align-items-center app-gap-10'>
            <button
              disabled={buttonsDisabled}
              onClick={cancelSelection}
              type='button'
              className='app-button-disabled-special app-secondary-button app-uppercase'
            >
              {t('Cancel')}
            </button>
            <button
              disabled={buttonsDisabled}
              onClick={async () => {
                await unblockAccounts();
                reset();
              }}
              type='button'
              className='app-button-disabled-special app-primary-button app-uppercase'
            >
              {t('Unblock')}
            </button>
          </div>
        </div>
        <AppShowLoading showLoading={loading}>
          <div className='app-table app-table-content app-table-blocked-acconts app-table-billing-white-list app-gap-20'>
            <div className='app-table-3-cols-with-icon app-table-header-row'>
              <div className='app-table-header-row-with-sort'>
                <span className='app-uppercase'>{t('Company')}</span>
                <AppPopupMenu
                  options={(stringSortOptions as unknown) as string[]}
                  selectedOption={companyNameSort}
                  onOptionSelected={(option) => {
                    reset();
                    setCompanyNameSort(option);
                  }}
                  getOptionLabel={getStringSortOptionLabel as any}
                  render={() => <AppSortIcon applied={!!companyNameSort} />}
                />
              </div>
              <div className='app-uppercase'>{t('Address')}</div>
              <div className='app-table-header-row-with-sort'>
                <span className='app-uppercase'>{t('Status')}</span>
                <AppPopupMenu
                  options={(stringSortOptions as unknown) as string[]}
                  selectedOption={statusSort}
                  onOptionSelected={(option) => {
                    reset();
                    setStatusSort(option)
                  }}
                  getOptionLabel={getStringSortOptionLabel as any}
                  render={() => <AppSortIcon applied={!!statusSort} />}
                />
              </div>
            </div>
            <div
              className='app-flex-vertical-scrollable app-gap-20 app-d-flex app-flex-column'
              ref={companiesRowsRef}
            >
              {companies.map(company => (
                <div
                  key={company.id}
                  data-hover-disabled='true'
                  className='app-table-3-cols-with-icon app-table-content-row'
                >
                  <div>{company.companyName}</div>
                  <div>{formatCompanyAddress(company.billingAddress)}</div>
                  <div>{<AccountStatusLabel accountStatus={company.accountStatusAuto} />}</div>
                  <div>
                    {company.accountStatusAuto === CompanyAccountStatus.Ok && (
                      <ToggleIcon
                        checked={isCompanySelected(company.id)}
                        setChecked={(checked: boolean) => setCompanySelected(company.id, checked)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='app-content-footer-pagination app-mt-15 app-flex-grow-1 app-d-flex app-align-items-center app-justify-content-center'>
            {companies.length > 0 && isScrollVisible && (
              <AppTablePagination
                totalPages={maxCompanyPage}
                activePageIndex={page - 1}
                onPageSelect={pageIndex => setPage(pageIndex + 1)}
              />
            )}
          </div>
        </AppShowLoading>
      </AppShowLoading>
    </HomeShell>
  );
}
