import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import PaxtonLogoSvg from '../../../assets/MyPaxton Logo.svg';
import './ErrorPage.scss';
import { ReactElement } from 'react';

export default function ErrorPage({
  errorCode,
  errorMessage,
  errorGraphic,
}: {
  errorCode: string;
  errorMessage: string;
  errorGraphic: ReactElement<any>;
}) {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const goToHome = () => setLocation('/');

  return (
    <div className="app-error-page">
      <div className="app-error-page-background-container" />
      <div className="app-error-page-logo">
        <img
          src={PaxtonLogoSvg}
          alt="logo"
        />
      </div>
      <div className="app-error-page-container">
        <div className="app-flex-1 app-error-page-graphic">
          {errorGraphic}
        </div>
        <div className="app-flex-1 app-error-page-content">
          <div className="app-error-page-title2">
            {`${t('Errors_Error')} ${t(errorCode)}`}
          </div>
          <div className="app-error-page-title3">
            {t(errorMessage)}
          </div>
          <button
            type="button"
            onClick={goToHome}
            className="app-primary-button"
          >
            {t('Errors_BackToHome')}
          </button>
        </div>
      </div>
    </div>
  );
}
