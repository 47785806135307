import { useTranslation } from 'react-i18next';
import { useLazyLoad } from '../../appBackend/useLazyLoad';
import { ISite } from '../../appBackend/useSites';
import { useState } from 'react';
import { Urls } from '../../backend/urls';
import AppSelect from './AppSelect';

export default function AppSitesSelect({
  site,
  setSite,
  companyId,
  disabled = false,
  enableReset = false,
}: {
  disabled?: boolean;
  site?: ISite | null;
  setSite: (site: ISite | null) => void;
  companyId?: string;
  enableReset?: boolean;
}) {
  const { t } = useTranslation();

  const [siteSearch, setSiteSearch] = useState('');

  const siteUrl = companyId ? Urls.Sites(companyId) : undefined;

  const {
    items: sites,
    loadMore: loadMoreSites,
    lastPageLoaded: lastPageOfSitesLoaded,
    loading: isSitesLoading,
  } = useLazyLoad<ISite>(siteUrl, {
    search: siteSearch,
  }, {
    initialPage: 0,
  });

  const getSiteOptionTitle = (site?: ISite | null) => {
    if (!site) {
      return '';
    }

    return site.siteName;
  };

  const getFilteredSites = () => {
    return sites;
  };

  const sitesOptions = getFilteredSites().map(getSiteOptionTitle);

  const setSelectedSiteFromOption = (siteTitle: string) => {
    const site = sites.find(c => getSiteOptionTitle(c) === siteTitle);
    setSite(site || null);
  };

  return (
    <AppSelect
      disabled={ disabled || !companyId }
      enableReset={ enableReset }
      className='app-form-input'
      outlineSearch
      outlineSearchPlaceholder={ t('SearchSites') }
      onOutlineSearchChange={ e => {
        setSiteSearch(e.target.value);
      } }
      onClosed={ () => {
        setSiteSearch('');
      } }
      onOptionsScrolledToBottom={ loadMoreSites }
      areOptionsLoading={ isSitesLoading }
      lastPageLoaded={ lastPageOfSitesLoaded }
      showNoOptions
      incomingValue={ getSiteOptionTitle(site) || '' }
      options={ sitesOptions }
      placeholder={ t('SelectSite') }
      onOptionSelected={ setSelectedSiteFromOption }
    />
  );
}
