import WhiteList from './routes/home/whiteList/WhiteList';
import Billing from './routes/home/billing/Billing';
import Diagnostics from './routes/home/diagnostics/Diagnostics';
import BlockedAccounts from './routes/home/BlockedAccounts';
import { AppPermissionComponentGuard } from './shared/appUIFramework/permissions/AppPermissionGuard';
import { UserRole } from './shared/backend/auth/auth.claims';
import SupportServerAccess from './routes/supportServerAccess/SupportServerAccess';
import SupportServerAccessDevMode from './routes/supportServerAccess/SupportServerAccessDevMode';
import { Redirect, Route, Switch } from 'wouter';
import EntryAnalytics from './routes/home/entryAnalytics/EntryAnalytics';
import TransferSite from './routes/transferSite/TransferSite';
import ServiceHealth from './routes/home/ServiceHealth';
import PageNotFound from './routes/PageNotFound';
import { useSupportServerAccessFeatureFlags } from './routes/supportServerAccess/SupportServerAccess.featureFlag';
import MaintenanceEmailsConfiguration from './routes/maintenanceEmails/MaintenanceEmailsConfiguration';
import MaintenanceEmailsLog from './routes/maintenanceEmails/MaintenanceEmailsEmailLog';
import Invoicing from './routes/invoicing/Invoicing';
import InvoiceDetails from './routes/invoicing/InvoiceDetails';

export default function AppRoutes() {
  const { featureFlags } = useSupportServerAccessFeatureFlags();
  return (
    <Switch>
      <Route path='/'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.User]}>
          <Redirect to='/home' />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/home'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.User]}>
          <EntryAnalytics />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/home/entry-analytics'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.User]}>
          <EntryAnalytics />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/home/service-health'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.User]}>
          <ServiceHealth />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/home/diagnostics'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.User]}>
          <Diagnostics />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/home/billing'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
          <Billing />
        </AppPermissionComponentGuard>
      </Route>
      {featureFlags.whiteListEnabled && (
        <Route path='/home/whitelist'>
          <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
            <WhiteList />
          </AppPermissionComponentGuard>
        </Route>
      )}
      <Route path='/home/blocked-accounts'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
          <BlockedAccounts />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/companies/transfer'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
          <TransferSite />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/maintenance-emails'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
          <Redirect to='/maintenance-emails/configuration' />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/maintenance-emails/configuration'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
          <MaintenanceEmailsConfiguration />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/maintenance-emails/email-log'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.DevOps]}>
          <MaintenanceEmailsLog />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/invoicing'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
          <Invoicing />
        </AppPermissionComponentGuard>
      </Route>
      <Route path='/invoicing/:customerReference/:invoiceId'>
        <AppPermissionComponentGuard requiredRoles={[UserRole.Admin]}>
          <InvoiceDetails />
        </AppPermissionComponentGuard>
      </Route>
      {featureFlags.isSupportServerAccessEnabled && (
        <Route path='/server-access'>
          <AppPermissionComponentGuard requiredRoles={[UserRole.RemoteSupportAccessP10]}>
            <SupportServerAccess />
          </AppPermissionComponentGuard>
        </Route>
      )}
      {featureFlags.isDevModeEnabled && (
        <Route path='/dev-mode-server-access'>
          <AppPermissionComponentGuard requiredRoles={[UserRole.RemoteSupportAccessP10]}>
            <SupportServerAccessDevMode />
          </AppPermissionComponentGuard>
        </Route>
      )}
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}
