import { useFormatNumber } from '../../../../shared/formatters/formatNumber';
import { ReactComponent as GrowthDecreaseIcon } from '../../../../assets/icons/home page icons/Growth decrease icon.svg';
import { ReactComponent as GrowthIncreaseIcon } from '../../../../assets/icons/home page icons/Growth UP.svg';

export default function AppAnalyticsDelta({ delta }: { delta: number }) {
  const formatNumber = useFormatNumber();

  return (
    <>
      { delta === 0 ? <></> : (
        <div className='app-analytics-delta-count'>
          { delta > 0 ? <GrowthIncreaseIcon /> : <GrowthDecreaseIcon /> }
          <span>{ formatNumber(delta) }</span>
        </div>
      ) }
    </>
  );
}
