import { useTranslation } from 'react-i18next';
import { useTotalCountWithDelta } from '../../../../shared/appBackend/useMetrics';
import CompanyIcon from '../../../../assets/icons/home page icons/entry-analytics-company-icon.svg';
import AppAnalyticsCountIcon from './AppAnalyticsCountIcon';
import AppAnalyticsSmallIcon from './AppAnalyticsSmallIcon';
import {
  ReactComponent as CompaniesIcon,
} from '../../../../assets/icons/home page icons/entry-analytics-companies-icon.svg';
import { EntryAnalyticsContext } from '../EntryAnalyticsContext';
import { useContext } from 'react';

function PaymentAdmins({ oneLine }: { oneLine?: boolean }) {
  const { t } = useTranslation();
  const { totalCount, totalCountDelta, isDeltaRequired } = useTotalCountWithDelta();

  return (
    <>
      <div
        className={ `app-d-flex app-align-items-center app-justify-content-center app-w-100pcnt ${
          oneLine ? 'app-h-50pcnt' : ''
        }` }
      >
        <div className='app-analytics-widget-body-small-icons app-w-212'>
          <AppAnalyticsSmallIcon
            icon={ <img src={ CompanyIcon } alt={ 'Icon' } /> }
            count={ totalCount?.paymentAdminsCount }
            isDeltaRequired={ isDeltaRequired }
            delta={ totalCountDelta?.paymentAdminsCount }
            title={ t('PaymentAdmins') }
          />
          <AppAnalyticsSmallIcon
            icon={ <img src={ CompanyIcon } alt={ 'Icon' } /> }
            count={ totalCount?.systemAdminsCount }
            isDeltaRequired={ isDeltaRequired }
            delta={ totalCountDelta?.systemAdminsCount }
            title={ t('SystemAdmins') }
          />
          { oneLine && (
            <AppAnalyticsSmallIcon
              icon={ <img src={ CompanyIcon } alt={ 'Icon' } /> }
              count={ totalCount?.siteAdminsCount }
              isDeltaRequired={ isDeltaRequired }
              delta={ totalCountDelta?.siteAdminsCount }
              title={ t('SiteAdmins') }
            />
          ) }
        </div>
      </div>
      { !oneLine && (
        <div className='app-d-flex app-align-items-center app-justify-content-center app-w-100pcnt'>
          <div className='app-analytics-widget-body-small-icons app-w-212'>
            <AppAnalyticsSmallIcon
              icon={ <img src={ CompanyIcon } alt={ 'Icon' } /> }
              count={ totalCount?.siteAdminsCount }
              isDeltaRequired={ isDeltaRequired }
              delta={ totalCountDelta?.siteAdminsCount }
              title={ t('SiteAdmins') }
            />
            <div style={ { visibility: 'hidden', width: '64px' } }>Spacer</div>
          </div>
        </div>
      ) }
    </>
  );
}

export default function CompaniesWidget() {
  const { t } = useTranslation();

  const { totalCount, totalCountDelta, isDeltaRequired } = useTotalCountWithDelta({
    ignoreSite: true,
  });

  const { company } = useContext(EntryAnalyticsContext);

  return (
    <>
      { !company && (
        <div className='app-home-widget app-analytics-widget app-w-50pcnt'>
          <div className='app-home-widget-left'>
            <div className='app-home-widget-title-text'>
              { t('Companies') }
            </div>
            <div className='app-analytics-widget-big-icon-container'>
              <div className='app-analytics-widget-big-icon app-analytics-widget-companies-big-icon'>
                <AppAnalyticsCountIcon
                  icon={ <CompaniesIcon /> }
                  count={ totalCount?.companiesCount }
                  isDeltaRequired={ isDeltaRequired }
                  delta={ totalCountDelta?.companiesCount }
                  title={ t('Companies') }
                />
              </div>
            </div>
          </div>
          <div className='app-home-widget-right'>
            <div style={ { visibility: 'hidden' } }>Spacer</div>
            <PaymentAdmins />
          </div>
        </div>
      ) }
      { company && (
        <div className='app-home-widget app-analytics-widget app-flex-wrap app-max-width-460 app-min-height-320'>
          <div className='app-home-widget-title-text app-w-100pcnt'>
            { t('Company') }
          </div>

          <PaymentAdmins oneLine />
        </div>
      ) }
    </>
  );
}
