import { CSSProperties, ReactNode } from 'react';
import LoadingBubbles from '../../../assets/loading-bubbles.svg';

export default function AppShowLoading({ inline, showLoading, style, children }: { style?: CSSProperties, inline?: boolean, showLoading: boolean, children?: ReactNode }) {
  const balls = (
    <img
      style={style || (inline ? undefined : {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% - 110px)',
      })}
      className="app-show-loading-image"
      src={LoadingBubbles}
      alt="Loading..."
    />
  );

  return (
    <>
      {
            showLoading ? balls : children
        }
    </>
  );
}
