import { AuthenticationResult, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { ReactNode, createContext, useContext } from 'react';

const scopeForAccessToken = process.env.REACT_APP_AUTH_AAD_BACKEND_SCOPE!;

export class AppMsal {
  private readonly configuration: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_AUTH_AAD_MSAL_CLIENT_ID!,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTH_AAD_MSAL_TENANT_ID!}`,
      redirectUri: process.env.REACT_APP_AUTH_AAD_REDIRECT_URL!,
      postLogoutRedirectUri: process.env.REACT_APP_AUTH_AAD_REDIRECT_URL!,
    },
  };

  public readonly instance: PublicClientApplication = new PublicClientApplication(this.configuration);

  getAccount() {
    return this.instance.getAllAccounts()[0];
  }

  async acquireTokenSilent(): Promise<AuthenticationResult> {
    return this.instance.acquireTokenSilent({
      account: this.getAccount(),
      scopes: [scopeForAccessToken],
    });
  }

  signIn() {
    return this.instance.loginRedirect({
      authority: this.configuration.auth.authority,
      scopes: [scopeForAccessToken],
    });
  }

  async logout() {
    await this.instance.logoutRedirect();
  }
}


export const appMsal = new AppMsal();
const MsalContext = createContext<AppMsal>(appMsal);

export function useAppMsal() {
  const msal = useContext(MsalContext);
  if (!msal) {
    throw new Error('useMsal must be used within a MsalProvider');
  }
  return msal;
}

export function AppMsalProvider({ children }: { children: ReactNode }) {
  return (
    <MsalContext.Provider value={appMsal}>
      {children}
    </MsalContext.Provider>
  );
}
