import { createContext } from 'react';
import { EntryRegionType } from '../../../shared/backend/urls';
import { ICompany } from '../../../shared/appBackend/useCompanies';
import { ISite } from '../../../shared/appBackend/useSites';
import { MobileTypeFilter } from '../components/MobileTypeDropdown';

interface IEntryAnalyticsContext {
  entryRegion: EntryRegionType;
  company: ICompany | null;
  site: ISite | null;
  mobileTypeFilter: MobileTypeFilter | null;
  startDate: Date;
  endDate: Date;
}

export const EntryAnalyticsContext = createContext<IEntryAnalyticsContext>({
  entryRegion: null,
  company: null,
  site: null,
  mobileTypeFilter: null,
  startDate: new Date(),
  endDate: new Date(),
});
