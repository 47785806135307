import {useSWRAndHandleErrors} from "./swr/useSWRAndHandleErrors";
import {Urls} from "../backend/urls";
import {httpGetJson, httpPost} from "../backend/http/http";

export function useEmails() {
  return useSWRAndHandleErrors<string[]>(Urls.GetEmails, httpGetJson, {
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    revalidateOnFocus: false,
    revalidateOnMount: true,
  }) 
}

export function updateEmails(emails: string[]) {
  return httpPost(Urls.UpdateEmails, JSON.stringify(emails));
}