import { useTranslation } from 'react-i18next';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/Download icon.svg';
import {
  IInvoice,
  IInvoicesQueryParams,
  InvoicePaymentType,
  InvoiceStatus,
  getInvoice,
  useInvoicesTotalNumber,
} from '../../shared/appBackend/useInvoices';
import { useRef, useState, MouseEvent } from 'react';
import './Invoicing.scss';
import { ICompany } from '../../shared/appBackend/useCompanies';
import { Urls } from '../../shared/backend/urls';
import { PAGE_SIZE, getTotalPages, useInfiniteWithPagination } from '../../shared/appBackend/useInfiniteWithPagination';
import {
  InvoicingSortOrder,
  getInvoicingSortOrder,
  getInvoicingSortOrderString,
  useSortDatesStringOptions,
} from '../../shared/appUIFramework/hooks/useSortStringOptions';
import { getUrlWithQueryParams } from '../../shared/backend/http/http';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import AppTablePagination from '../home/components/AppTablePagination';
import { useAppTableScrollStyles } from '../../styles';
import { Formats, formatDate } from '../../shared/formatters/formatDate';
import { formatMoney } from '../../shared/formatters/formatMoney';
import { AppSortIcon } from '../../shared/appUIFramework/components/SortAndFilterIcons';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import AppCompaniesSelect from '../../shared/appUIFramework/components/AppCompaniesSelect';
import { getStatusColorClassName, useFetchAndDownloadInvoice } from './invoicing.helper';
import { useLocation } from 'wouter';

function getStatusTranslationKey(status: InvoiceStatus) {
  switch (status) {
    case InvoiceStatus.Unpaid:
      return 'Unpaid';
    case InvoiceStatus.Pending:
      return 'Pending';
    case InvoiceStatus.Paid:
      return 'Paid';
    case InvoiceStatus.Failed:
      return 'Failed';
    case InvoiceStatus.Overdue:
      return 'Overdue';
    default:
      return 'Unknown';
  }
}

function getPaymentMethodTranslationKey(paymentMethod: InvoicePaymentType) {
  switch (paymentMethod) {
    case InvoicePaymentType.Auto:
      return 'Auto';
    case InvoicePaymentType.Manual:
      return 'Manual';
    default:
      return '-';
  }
}

export default function Invoicing() {
  const { t } = useTranslation();
  const [invoiceNoSearch, setInvoiceNoSearch] = useState('');
  const [dateSort, setDateSort] = useState<InvoicingSortOrder | undefined>(InvoicingSortOrder.Descending);
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);

  const queryParams = {
    invoiceNoSearch,
    dateSort,
    application: 'EntryApp',
    customerReference: selectedCompany?.customerReferenceId,
  } as IInvoicesQueryParams;
  const totalInvoicesNumber = useInvoicesTotalNumber(queryParams);
  const totalPages = getTotalPages(PAGE_SIZE, totalInvoicesNumber);
  const url = getUrlWithQueryParams(Urls.Invoices, queryParams);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const {
    items: invoices,
    loading,
    reset,
    page,
    setPage,
  } = useInfiniteWithPagination<IInvoice>(url, totalPages, {
    adjustPage: true,
    scrollRef: tableRef,
  });

  const [, setLocation] = useLocation();
  const goToInvoiceDetails = (customerReference: string, invoiceId: number) => {
    setLocation('/invoicing/' + customerReference + '/' + invoiceId);
  };

  const isScrollVisible = useAppTableScrollStyles({
    tableContentRef: tableRef,
    isTableVisible: invoices.length > 0,
  });

  const { options: stringSortOptions, getOptionLabel: getStringSortOptionLabel } = useSortDatesStringOptions();

  const fetchAndDownloadInvoice = useFetchAndDownloadInvoice(getInvoice);

  const downloadPdf = async (invoiceId: number, customerReference: string, e: MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    await fetchAndDownloadInvoice(invoiceId, customerReference);
  };

  return (
    <>
      <AppContentToolbar>
        <span className='app-toolbar-breadcrump'>{ t('Invoices') }</span>
      </AppContentToolbar>
      <div className='app-content'>
        <div className='app-d-flex app-justify-content-between app-gap-20 app-mb-20'>
          <div className='app-form-control app-form-control-search'>
            <div className='app-form-control-input app-no-ml'>
              <div className='app-form-control-search-icon'>
                <SearchIcon />
              </div>
              <AppCompaniesSelect
                searchMode
                placeholderTranslationKey='SearchCompanies'
                company={ selectedCompany }
                setCompany={ c => {
                  reset();
                  setSelectedCompany(c);
                } }
              />
            </div>
          </div>
          <div className='app-form-control app-form-control-search'>
            <div className='app-form-control-input app-no-ml'>
              <div className='app-form-control-search-icon'>
                <SearchIcon />
              </div>
              <input
                type='text'
                placeholder={ t('SearchInvoiceNo') }
                value={ invoiceNoSearch }
                className='app-transfer-list-search'
                onChange={ event => {
                  reset();
                  setInvoiceNoSearch(event.target.value);
                } }
              />
            </div>
          </div>
        </div>
        <div className='app-table app-h-100'>
          <div
            className={ `app-table-invoicing-table-cols app-table-header-row ${isScrollVisible ? 'app-pr-57' : ''}` }
          >
            <div className='app-uppercase'>{ t('NO') }</div>
            <div className='app-table-header-row-with-sort'>
              <span className='app-uppercase'>{ t('Date') }</span>
              <AppPopupMenu
                options={ ((stringSortOptions as unknown) as string[]).reverse() }
                selectedOption={ getInvoicingSortOrderString(dateSort) }
                onOptionSelected={ option => {
                  reset();
                  setDateSort(getInvoicingSortOrder(option));
                } }
                getOptionLabel={ getStringSortOptionLabel as any }
                render={ () => <AppSortIcon applied={ !!dateSort } /> }
              />
            </div>
            <div className='app-uppercase'>{ t('Total') }</div>
            <div className='app-uppercase'>{ t('Type') }</div>
            <div className='app-uppercase'>{ t('Status') }</div>
          </div>
          <AppShowLoading showLoading={ loading }>
            <div
              className={ `app-flex-vertical-scrollable app-gap-20 app-d-flex app-flex-column ${
                isScrollVisible ? 'app-pr-20' : ''
              }` }
              ref={ tableRef }
            >
              { invoices.map((invoice, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus
                <div
                  role='row'
                  onClick={ () => goToInvoiceDetails(invoice.customerReference, invoice.no) }
                  className='app-table-content-row app-table-invoicing-table-cols'
                  key={ index }
                >
                  <div>{ invoice.no }</div>
                  <div>{ formatDate(invoice.date, Formats.DateCommon) }</div>
                  <div>{ formatMoney(invoice.currency, invoice.total) }</div>
                  <div className='app-uppercase'>{ t(getPaymentMethodTranslationKey(invoice.type)) }</div>
                  <div
                    className={ `${
                      getStatusColorClassName(invoice.status)
                    } app-payment-overview-status-text app-uppercase` }
                  >
                    { t(getStatusTranslationKey(invoice.status)) }
                  </div>
                  <div className='app-table-action-icon'>
                    <DownloadIcon
                      onClick={ e => downloadPdf(invoice.no, invoice.customerReference, e) }
                    />
                  </div>
                </div>
              )) }
            </div>
            { invoices.length > 0 && isScrollVisible && (
              <div className='app-d-flex app-w-100pcnt app-align-items-center app-justify-content-center'>
                <AppTablePagination
                  totalPages={ totalPages }
                  activePageIndex={ page - 1 }
                  onPageSelect={ pageIndex => setPage(pageIndex) }
                />
              </div>
            ) }
          </AppShowLoading>
        </div>
      </div>
    </>
  );
}
