import { ReactComponent as CheckedIcon } from '../../../assets/icons/Selected.svg';
import { ReactComponent as UncheckedIcon } from '../../../assets/icons/Not selected.svg';


export default function AppRoundCheckbox({ checked, onClick }: { checked: boolean, onClick?: () => void }) {
  return (
    <div className="app-round-checkbox" onClick={onClick}>
      { checked ? <CheckedIcon/> : <UncheckedIcon/> }
    </div>
  );
}
