import './App.css';
import './assets/styles/styles.scss';
import './shared/i18n/config';
import PortalShell from './shared/appUIFramework/components/PortalShell';
import { AuthProvider } from './shared/backend/auth/auth';
import WelcomePage from './routes/WelcomePage';
import RemoteAccessFeatureFlagsProvider from './routes/supportServerAccess/SupportServerAccess.featureFlag';
import AppRoutes from './AppRoutes';

export default function App() {
  return (
    <RemoteAccessFeatureFlagsProvider>
      <AuthProvider
        unauthenticatedTemplate={<WelcomePage />}
        authenticatedTemplate={
          <PortalShell>
            <AppRoutes />
          </PortalShell>
        }
      />
    </RemoteAccessFeatureFlagsProvider>
  );
}
