import { useTranslation } from 'react-i18next';
import { ReactComponent as RebootSuccessIcon } from '../../../assets/reboot_success.svg';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function RebootSuccessPopup() {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      onHeaderClose={ () => closePopup() }
      headerTitle='RebootPanel'
      popupBody={ 
        <div className='app-d-flex app-flex-column app-align-items-center'>
          <RebootSuccessIcon />
          <div className='app-d-flex app-mw-539 app-flex-column app-align-items-center app-justify-content-center app-pt-65 app-gap-20 app-font-20 app-color-primary-black app-lh-28'>
            <div className='app-weight-600 app-uppercase'>
              { t('RebootConfirmed') }
            </div>
            <div className='app-text-align-center'>
              { t('ThePanelHasReceivedMessageToReboot') }
            </div>
          </div>
        </div>
       }
    />
  );
}
