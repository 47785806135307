import {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close-icon.svg';

export function AppPopupLayout({
                                   headerTitle,
                                   popupBody,
                                   buttons,
                                   extraClass = '',
                                   onHeaderClose,
                                   headerSubtitle,
                               }: {
    headerTitle?: string,
    popupBody: ReactNode,
    buttons?: ReactNode,
    extraClass?: string,
    onHeaderClose?: () => void,
    headerSubtitle?: string,
}) {
    const { t } = useTranslation();
    return (
        <div className={`app-popup-content ${extraClass}`}>
            {(headerTitle || onHeaderClose) && (
                <div className="app-popup-header">
                    <span className={`app-popup-title ${onHeaderClose && 'app-pl-18'}`}>{headerTitle && t(headerTitle)}</span>
                    {onHeaderClose && (
                        <span className="app-popup-header-close">
                            <CloseIcon onClick={() => onHeaderClose()} />
                        </span>)
                    }
                    {headerTitle && <div className="app-popup-line" />}
                    {headerSubtitle && <span className="app-popup-subtitle">{headerSubtitle}</span>}
                </div>
            )}
            <div className="app-popup-body">
                {popupBody}
            </div>
            {buttons && (
                <div className="app-popup-buttons">
                    {buttons}
                </div>
            )}
        </div>
    );
}
