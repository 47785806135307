import { useEffect } from 'react';
import { useAppMsal } from '../../backend/auth/auth.msal';

const LAST_ACTION_AT_KEY = 'EntryAdminPortal_LastActionAt';
const envSeconds = +(process.env.REACT_APP_IDLE_TIMEOUT_SECONDS || 0);
const defaultMinutes = 30;
const secondsInMinute = 60;
const msInSeconds = 1000;
const IDLE_TIME_MS = envSeconds
  ? envSeconds * msInSeconds
  : defaultMinutes * secondsInMinute * msInSeconds;

function setLastActionAt() {
  window.localStorage.setItem(LAST_ACTION_AT_KEY, new Date().toString());
}

function getLastActionAt(): Date | null {
  const dateString = window.localStorage.getItem(LAST_ACTION_AT_KEY);
  if (!dateString) {
    return null;
  }

  return new Date(dateString);
}

export function useLogoutOnIDLE() {
  const msal = useAppMsal();
  const logoutIfIDLE = () => {
    const now = new Date();
    const lastActionAt = getLastActionAt();
    if (!lastActionAt) {
      throw new Error('LastActionAt must be set');
    }

    const timePassedMs = now.getTime() - lastActionAt.getTime();
    if (timePassedMs >= IDLE_TIME_MS) {
      msal.logout();
    }
  };

  useEffect(() => {
    let timeoutId = setTimeout(logoutIfIDLE, IDLE_TIME_MS);
    setLastActionAt();
    window.addEventListener('click', () => {
      setLastActionAt();
      clearTimeout(timeoutId);
      timeoutId = setTimeout(logoutIfIDLE, IDLE_TIME_MS);
    });

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
}
