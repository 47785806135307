import i18n from 'i18next';
import { CurrencyCode, CurrencyCodeString, CurrencyCodeSymbol } from '../appBackend/useInvoices';

const CURRENCY_DECIMALS_AMOUNT = 2;

export function roundToDecimalPlaces(value: number, decimalPlaces: number) {
  const multiplier = 10 ** decimalPlaces;
  return Math.round(value * multiplier) / multiplier;
}

// when we display currency on the UI, we want to round it to 2 decimal places, and if don't round in calculation
// the value calculated without rounding may be different
// so during calculation we should apply rounding
export function roundAsCurrencyOnUI(value: number) {
  return roundToDecimalPlaces(value, CURRENCY_DECIMALS_AMOUNT);
}

function getFormattedMoneyOrPlaceholder(amount: number, currencySymbol: CurrencyCodeSymbol): string {
  return Number.isNaN(amount)
    ? i18n.t('-')
    : `${currencySymbol}${roundAsCurrencyOnUI(amount).toFixed(CURRENCY_DECIMALS_AMOUNT)}`;
}

function getCurrencySymbol(currencyCode: CurrencyCode | CurrencyCodeString) {
  switch (currencyCode) {
    case CurrencyCode.USD:
    case CurrencyCodeString.USD:
      return CurrencyCodeSymbol.USD;
    case CurrencyCode.GBP:
    case CurrencyCodeString.GBP:
      return CurrencyCodeSymbol.GBP;
    case CurrencyCode.EUR:
    case CurrencyCodeString.EUR:
      return CurrencyCodeSymbol.EUR;
    default:
      return currencyCode;
  }
}

export function formatMoney(currencyCode: CurrencyCode | CurrencyCodeString, amount: number): string {
  const currencySymbol = getCurrencySymbol(currencyCode);

  return getFormattedMoneyOrPlaceholder(amount, currencySymbol);
}
