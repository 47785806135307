import { useCallback, useEffect, useRef, useState } from 'react';
import { Formats, formatDate } from '../../formatters/formatDate';
import DropdownIcon from '../../../assets/icons/Expand icon.svg';
import ResetCrossIcon from '../../../assets/reset_cross_icon.svg';
import BackActiveIcon from '../../../assets/Breadcrumb arrow.svg';
import ForwardInactiveIcon from '../../../assets/Breadcrumb arrow inactive.svg';

import './AppMonthRangePicker.scss';
import AppOutsideListener from './AppOutsideListener';
import { dir } from 'console';

const monthLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

function AppDatesRow(
  { months, selectMonth, getMonthClass }: {
    months: number[];
    selectMonth: (month: number) => void;
    getMonthClass: (month: number) => string;
  },
) {
  const getBgClass = (index: number) => {
    if (index === 0) {
      return 'app-month-range-picker-month-background-first';
    }

    if (index === 2) {
      return 'app-month-range-picker-month-background-last';
    }

    return '';
  };

  return (
    <div className='app-month-range-picker-month-row'>
      { months.map((month, index) => (
        <div key={month} className={ `app-month-range-picker-month-background ${getMonthClass(month)} ${getBgClass(index)}` }>
          <div
            className={ `app-month-range-picker-month ${getMonthClass(month)}` }
            onClick={ () =>
              selectMonth(month) }
          >
            { monthLabels[month] }
          </div>
        </div>
      )) }
    </div>
  );
}

const UiMonthClasses = {
  Disabled: 'app-month-range-picker-disabled',
  StartDate: 'app-month-range-picker-start-date',
  EndDate: 'app-month-range-picker-end-date',
  InRange: 'app-month-range-picker-in-range',
};

function isStartAndEndEqualToNow(startDate: Date, endDate: Date) {
  const now = new Date();
  return startDate.getFullYear() === now.getFullYear()
    && startDate.getMonth() === now.getMonth()
    && endDate.getFullYear() === now.getFullYear()
    && endDate.getMonth() === now.getMonth();
}

export default function AppMonthRangePicker({
  startDateForm,
  endDateForm,
  setStartDateForm,
  setEndDateForm,
}: {
  startDateForm: Date;
  endDateForm: Date;
  setStartDateForm: (date: Date) => void;
  setEndDateForm: (date: Date) => void;
}) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectingStart, setSelectingStart] = useState(true);
  const [startDate, setStartDate] = useState<Date>(startDateForm);
  const [endDate, setEndDate] = useState<Date>(endDateForm);
  const [pickerVisible, setPickerVisible] = useState(false);

  const closeVisible = !isStartAndEndEqualToNow(startDateForm, endDateForm);

  useEffect(() => {
    setStartDate(startDateForm);
    setEndDate(endDateForm);
  }, [startDateForm, endDateForm]);

  const apply = useCallback(() => {
    setStartDateForm(startDate);
    setEndDateForm(endDate);
    setPickerVisible(false);
  }, [startDate, endDate, setStartDateForm, setEndDateForm]);

  const getMonthClass = useCallback((month: number) => {
    if (
      startDate != null && month === startDate.getMonth() && year === startDate.getFullYear()
      && (endDate == null
        || (endDate.getMonth() === startDate.getMonth() && endDate.getFullYear() === startDate.getFullYear()))
    ) {
      return `${UiMonthClasses.StartDate} ${UiMonthClasses.EndDate}`;
    }

    if (startDate != null && month === startDate.getMonth() && year === startDate.getFullYear()) {
      return UiMonthClasses.StartDate;
    }

    if (endDate != null && month === endDate.getMonth() && year === endDate.getFullYear()) {
      return UiMonthClasses.EndDate;
    }

    const date = new Date(year, month);
    if (startDate != null && endDate != null && date > startDate && date < endDate) {
      return UiMonthClasses.InRange;
    }

    if (!selectingStart && startDate != null && date < startDate) {
      return UiMonthClasses.Disabled;
    }

    const now = new Date();
    if (date > now) {
      return UiMonthClasses.Disabled;
    }

    return '';
  }, [year, selectingStart, startDate, endDate]);

  useEffect(() => {
    if (isStartAndEndEqualToNow(startDate, endDate)) {
      setSelectingStart(true);
    }
  }, [startDate, endDate]);

  const selectMonth = useCallback((month: number) => {
    const currenState = getMonthClass(month);
    if (currenState === UiMonthClasses.Disabled) {
      return;
    }

    const date = new Date(year, month);
    if (selectingStart) {
      setStartDate(date);
      setEndDate(date);
      setSelectingStart(false);
    } else {
      setEndDate(new Date(year, month));
      setSelectingStart(true);
    }
  }, [year, selectingStart, setStartDate, setEndDate, getMonthClass]);

  const value = `${formatDate(startDateForm, Formats.ShortMonthYear)} - ${
    formatDate(endDateForm, Formats.ShortMonthYear)
  }`;
  const calendarRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className='app-w-200 app-position-relative'>
      <div className='app-form app-w-200'>
        <div className='app-form-control'>
          <div className='app-form-control-input app-unselectable'>
            <input
              type='text'
              readOnly
              onClick={ () => {
                setStartDate(startDateForm);
                setEndDate(endDateForm);
                setSelectingStart(true);
                setPickerVisible(!pickerVisible);
              } }
              className='app-form-input app-cursor-pointer app-unselectable app-py-25-important'
              value={ value }
            />
            <img
              src={ closeVisible ? ResetCrossIcon : DropdownIcon }
              onClick={ () => {
                if (closeVisible) {
                  setStartDateForm(new Date());
                  setEndDateForm(new Date());
                  setPickerVisible(false);
                } else {
                  setPickerVisible(!pickerVisible);
                }
              } }
              alt="''"
              className='app-position-absolute app-r-15 app-cursor-pointer'
            />
          </div>
        </div>
      </div>
      { pickerVisible && (
        <AppOutsideListener
          inputRef={ calendarRef }
          onClick={ () => {
            setPickerVisible(false);
          } }
        >
          <div
            ref={ calendarRef }
            className='app-position-absolute app-r-0 app-z-index-1 app-bg-white app-p-12 app-border-radius-18'
          >
            <div className='app-d-flex app-justify-content-between'>
              <div>
                <img className='app-cursor-pointer' src={ BackActiveIcon } alt='' onClick={ () => setYear(year - 1) } />
              </div>
              <div className='app-font-16 app-weight-600'>
                { year }
              </div>
              <div>
                { year < new Date().getFullYear()
                  && (
                    <img
                      className='app-rotate-180 app-cursor-pointer'
                      src={ BackActiveIcon }
                      alt=''
                      onClick={ () => setYear(year + 1) }
                    />
                  ) }
                { year === new Date().getFullYear()
                  && <img src={ ForwardInactiveIcon } alt='' /> }
              </div>
            </div>
            <div className='app-d-flex app-gap-20 app-form app-mt-15 app-mb-10'>
              <div
                onClick={ () => setSelectingStart(true) }
                tabIndex={ 0 }
                className={ `app-select-date-input app-cursor-pointer ${
                  selectingStart ? 'app-select-date-input-focus' : ''
                }` }
              >
                <div>From:</div>
                <div className='app-weight-600'>{ formatDate(startDate, Formats.ShortMonthYear) }</div>
              </div>
              <div
                onClick={ () => setSelectingStart(false) }
                tabIndex={ 0 }
                className={ `app-select-date-input ${!selectingStart ? 'app-select-date-input-focus' : ''}` }
              >
                <div>To:</div>
                <div className='app-weight-600'>{ formatDate(endDate, Formats.ShortMonthYear) }</div>
              </div>
            </div>
            <div className='app-d-flex app-flex-column'>
              <AppDatesRow months={ [0, 1, 2] } selectMonth={ selectMonth } getMonthClass={ getMonthClass } />
              <AppDatesRow months={ [3, 4, 5] } selectMonth={ selectMonth } getMonthClass={ getMonthClass } />
              <AppDatesRow months={ [6, 7, 8] } selectMonth={ selectMonth } getMonthClass={ getMonthClass } />
              <AppDatesRow months={ [9, 10, 11] } selectMonth={ selectMonth } getMonthClass={ getMonthClass } />
              <button
                className='app-month-range-picker-apply-button app-primary-button app-mt-10'
                onClick={ () => apply() }
              >
                Apply
              </button>
            </div>
          </div>
        </AppOutsideListener>
      ) }
    </div>
  );
}
