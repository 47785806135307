import { useTranslation } from "react-i18next";
import { AppPopupLayout } from "../../shared/appUIFramework/popup/AppPopupLayout";
import { closePopup } from "../../shared/appUIFramework/popup/AppPopup";
import { ReactComponent as ConfirmationIcon } from "../../assets/supportServerAccess/leaveActiveSessionConfirmation.svg";

export default function ServerAccessCanLeavePopup() {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle="Confirmaion"
      popupBody={(
        <div className="app-d-flex app-flex-column app-align-items-center">
          <ConfirmationIcon className="app-w-86" />
          <div className="app-d-flex app-flex-column app-align-items-center app-pt-65 app-font-20 app-color-primary-black app-lh-28">
            <div>{t('AreYouSureYouWantToLeave')}</div>
            <div>{t('AllCredentialsWillBeCleared')}</div>
          </div>
        </div>
      )}
      buttons={(
        <>
          <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button app-uppercase">{t('GoBack')}</button>
          <button type="button" onClick={confirm} className="app-primary-button app-popup-right-button app-uppercase">{t('Leave')}</button>
        </>
      )}
    />
  );
}
