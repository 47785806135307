import { useTranslation } from 'react-i18next';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { useState } from 'react';

export default function RebootConfirmationPopup() {
  const { t } = useTranslation();
  const [engineerCode, setEngineerCode] = useState('');
  const [touched, setTouched] = useState<boolean>(false);

  const errors = {
    engineerCode: {
      required: !engineerCode,
    },
  };
  const anyErrors = Object.values(errors.engineerCode).some(Boolean);

  const cancel = () => closePopup();
  const confirm = () => closePopup(engineerCode);

  return (
    <AppPopupLayout
      headerTitle='RebootPanel'
      popupBody={
        <div className='app-d-flex app-flex-column app-align-items-center app-gap-42'>
          <div className='app-d-flex app-flex-column app-align-items-center app-gap-30 app-font-20 app-color-primary-black app-lh-28'>
            <span>{t('EnterYourEngineerCodeToRebootYourPanel')}</span>
            <span>{t('WhilteRebootingCallsWillNotBeAbleToBeMadeFromThePanel')}</span>
          </div>
          <div
            className='app-form-control'
            aria-invalid={touched && anyErrors}
          >
            <div className='app-form-control-label'>
              {t('EngineerCode')}
              <span className='app-text-secondary-red-color'>&nbsp;*</span>
            </div>
            <div className='app-form-control-input'>
              <input
                value={engineerCode}
                onChange={e => setEngineerCode(e.target.value)}
                onBlur={() => setTouched(true)}
                style={{ maxWidth: '543px' }}
                type='text'
                className='app-form-input app-form-input-outlined'
              />
            </div>
            {touched && anyErrors && (
              <div className='app-form-error'>
                {errors.engineerCode.required && t('FieldIsRequired')}
              </div>
            )}
          </div>
        </div>
      }
      buttons={
        <>
          <button type='button' onClick={cancel} className='app-secondary-button app-popup-left-button app-uppercase'>
            {t('Cancel')}
          </button>
          <button type='button' disabled={anyErrors} onClick={confirm} className='app-primary-button app-popup-right-button app-uppercase app-disabled-white-text'>
            {t('Reboot')}
          </button>
        </>
      }
    />
  );
}
