import {
  HTMLAttributes,
  MouseEvent,
  useCallback,
  useRef,
  useState,
  KeyboardEvent,
  ChangeEvent,
  useEffect,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';
import DropdownIcon from '../../../assets/icons/Expand icon.svg';
import ResetCrossIcon from '../../../assets/reset_cross_icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search icon.svg';
import AppShowLoading from './AppShowLoading';

function getFilteredOptions(options: string[], search: string): string[] {
  if (!search) {
    return options;
  }

  const filtered = options.filter(option => option.toLowerCase().includes(search.toLowerCase()));
  const filteredWithStarsWithAtTop = filtered.sort((a, b) => {
    if (a.toLowerCase().startsWith(search.toLowerCase()) && !b.toLowerCase().startsWith(search.toLowerCase())) {
      return -1;
    }
    if (!a.toLowerCase().startsWith(search.toLowerCase()) && b.toLowerCase().startsWith(search.toLowerCase())) {
      return 1;
    }
    return 0;
  });

  return filteredWithStarsWithAtTop;
}

export default function AppSelect({
  options,
  onOptionSelected,
  treatInputValueAsOption,
  showNoOptions,
  className,
  incomingValue, // passed from react-hook-form
  simpleMode, // passed when want to use as simple dropdown
  getOptionPrefix,
  outlineSearch = false,
  onOutlineSearchChange,
  onClosed,
  onOptionsScrolledToBottom,
  outlineSearchPlaceholder,
  areOptionsLoading,
  lastPageLoaded,
  placeholder,
  searchMode,
  enableReset,
  ...inputProps
}:
  & {
    options: string[];
    onOptionSelected?: (options: string) => void;
    treatInputValueAsOption?: boolean;
    showNoOptions?: boolean;
    outlineSearch?: boolean;
    onOutlineSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClosed?: () => void;
    onOptionsScrolledToBottom?: () => void;
    areOptionsLoading?: boolean;
    lastPageLoaded?: boolean;
    outlineSearchPlaceholder?: string;
    placeholder: string;
    getOptionPrefix?: (option: string) => ReactNode;
  }
  & HTMLAttributes<HTMLInputElement>
  & {
    disabled?: boolean;
    readOnly?: boolean;
    incomingValue?: string;
    simpleMode?: boolean;
    value?: string;
    searchMode?: boolean;
    enableReset?: boolean;
  }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const outlineSearchRef = useRef<HTMLInputElement>(null);
  const selectedOptionDivRef = useRef<HTMLDivElement>(null);
  const mainContainerDivRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(true);
  const changeIsHidden = useCallback((hidden: boolean) => {
    if (hidden && onClosed) {
      onClosed();
    }
    setIsHidden(hidden);
  }, [onClosed]);

  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const changeFilteredOptions = useCallback((isHiddenArg?: boolean) => {
    if (isHidden || isHiddenArg) {
      setFilteredOptions(options);
      return;
    }
    const searchValue = outlineSearch ? outlineSearchRef.current!.value : inputRef.current!.value;
    const filtered = !!incomingValue && !searchValue
      ? [incomingValue, ...options.filter(option => option !== incomingValue)]
      : getFilteredOptions(options, searchValue);
    setFilteredOptions(filtered);
  }, [incomingValue, options, isHidden, outlineSearch]);

  // listen to click outside of the dropdown and close options if need
  useEffect(() => {
    const hideOptionsIfClickOutSide = (e: Event) => {
      if (mainContainerDivRef.current && !mainContainerDivRef.current.contains(e.target as Node) && !isHidden) {
        changeIsHidden(true);
        const selectedValue = options.find(option => option === inputRef.current!.value);
        if (!selectedValue && !treatInputValueAsOption) {
          inputRef.current!.value = '';
        }

        const incomingOption = [...filteredOptions, ...options].find(option => option === incomingValue);
        if (incomingOption) {
          inputRef.current!.value = incomingOption;
        }
      }
    };
    window.addEventListener('click', hideOptionsIfClickOutSide);

    return () => window.removeEventListener('click', hideOptionsIfClickOutSide);
  }, [changeIsHidden, options, incomingValue, treatInputValueAsOption, filteredOptions, isHidden]);

  // when isHidden changed to true and there is a selected value, scroll that value into view
  useEffect(() => {
    if (selectedOptionDivRef.current && !isHidden) {
      selectedOptionDivRef.current!.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
    }
  }, [isHidden]);

  useEffect(() => {
    if (mainContainerDivRef.current?.classList.contains('app-select-not-in-view')) {
      const optionsDiv = mainContainerDivRef.current.querySelector('.app-select-items') as HTMLDivElement;
      if (optionsDiv && filteredOptions.length < 6) {
        const optionHeight = 37;
        optionsDiv.style.top = `-${optionHeight * filteredOptions.length}px`;
      } else if (optionsDiv != null) {
        optionsDiv.style.top = '';
      }
    }
  }, [filteredOptions]);

  const isSet = incomingValue != null;

  useEffect(() => {
    if (simpleMode) {
      return;
    }

    if (inputRef.current!.value !== incomingValue) {
      inputRef.current!.value = incomingValue as string || '';
    }
    changeFilteredOptions();
  }, [incomingValue, isSet, changeFilteredOptions, simpleMode]); // when incomingValue changes, input value should be updated too

  const showOptionsAndHandleInputClick = useCallback((event: MouseEvent<HTMLElement>) => {
    if (inputProps.disabled) {
      return;
    }

    changeIsHidden(false);
    const filteredOptions = incomingValue
      ? [incomingValue, ...options.filter(option => option !== incomingValue)]
      : options;
    setFilteredOptions(filteredOptions);
    if (inputProps.onClick) {
      inputProps.onClick(event as MouseEvent<HTMLInputElement>);
    }

    if (outlineSearch) {
      setTimeout(() => {
        outlineSearchRef.current!.focus();
      }, 200);
    }
  }, [changeIsHidden, inputProps, options, incomingValue, outlineSearch]);

  const selectOptionAndHideOptions = useCallback(
    (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
      const selectedValue = (event.target as HTMLElement).getAttribute('data-value') as string
        || (event.target as HTMLElement).parentElement?.getAttribute('data-value') as string;
      changeIsHidden(true);
      inputRef.current!.value = selectedValue;
      if (onOptionSelected) {
        onOptionSelected(selectedValue);
      }
    },
    [onOptionSelected, changeIsHidden],
  );

  const setFilteredAndSelected = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (inputProps.onChange) {
      inputProps.onChange(event);
    }
    changeFilteredOptions();
    const inputValue = (event.target as HTMLInputElement).value;
    if (onOptionSelected) {
      const selectedOption = inputValue ? filteredOptions.find(p => p === inputValue) : undefined;
      onOptionSelected(selectedOption || inputValue);
    }
  }, [changeFilteredOptions, filteredOptions, inputProps, onOptionSelected]);

  const reset = useCallback(() => {
    setIsHidden(true);
    changeFilteredOptions(true);
    inputRef.current!.value = '';
    if (onOptionSelected) {
      onOptionSelected('');
    }
  }, [onOptionSelected, changeFilteredOptions]);

  const shouldShowOptionsContainer = !isHidden && (filteredOptions.length > 0 || showNoOptions);
  return (
    <div
      ref={ mainContainerDivRef }
      aria-disabled={ inputProps.disabled }
      className={ `app-select${className ? ` ${className}` : ''}${getOptionPrefix ? ' app-select-with-prefix' : ''}` }
    >
      <div className='app-form-input-prefix'>
        { getOptionPrefix && getOptionPrefix(inputRef.current?.value || '') }
      </div>
      <input
        ref={ inputRef }
        { ...inputProps }
        style={ {
          display: outlineSearch ? 'none' : undefined,
        } }
        type='text'
        readOnly={ simpleMode ? true : inputProps.readOnly }
        onChange={ setFilteredAndSelected }
        onClick={ showOptionsAndHandleInputClick }
      />
      { outlineSearch && (
        <div
          className={ `app-select-div ${!inputRef?.current?.value ? 'app-select-div-placeholder' : ''}` }
          onClick={ showOptionsAndHandleInputClick }
        >
          { inputRef.current?.value || placeholder }
        </div>
      ) }
      { !searchMode && (!enableReset || !inputRef.current?.value)
        /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
        && <img
          src={ DropdownIcon }
          onClick={ showOptionsAndHandleInputClick }
          alt="''"
          className='app-select-dropdown-icon'
        /> }
      { enableReset && inputRef.current?.value 
        && (
        <img
            src={ ResetCrossIcon }
            onClick={ reset }
            alt="''"
            className='app-select-dropdown-icon'
            />
        )}
      { shouldShowOptionsContainer && (
        <div
          ref={ selectedOptionDivRef }
          className='app-select-items'
          aria-hidden={ isHidden }
        >
          { outlineSearch
            && (
              <div className='app-form-control app-form-control-search app-select-outline-search'>
                <div className='app-form-control-input'>
                  <div className='app-form-control-search-icon'>
                    <SearchIcon />
                  </div>
                  <input
                    type='text'
                    ref={ outlineSearchRef }
                    placeholder={ outlineSearchPlaceholder }
                    defaultValue={ '' }
                    onChange={ e => {
                      if (onOutlineSearchChange) {
                        onOutlineSearchChange(e);
                      }
                      changeFilteredOptions();
                    } }
                  />
                </div>
              </div>
            ) }
          { filteredOptions.length === 0 && showNoOptions && !areOptionsLoading
            && <div className='app-select-item'>{ t('Form_NoOptions') }</div> }
          <div
            onScroll={ (e: any) => {
              const target = e.target as HTMLDivElement;
              const isBottomReached = target.scrollTop + target.getBoundingClientRect().height >= target.scrollHeight;
              if (isBottomReached && onOptionsScrolledToBottom && !lastPageLoaded) {
                onOptionsScrolledToBottom();
              }
            } }
            className={ `app-select-items-scroll-area ${
              outlineSearch ? 'app-select-items-scroll-area-outline-enabled' : ''
            }` }
          >
            { filteredOptions.map(option => (
              <div
                role='option'
                aria-selected={ !!inputRef.current && inputRef.current?.value === option }
                ref={ !!inputRef.current && inputRef.current?.value === option
                  ? selectedOptionDivRef
                  : undefined }
                data-value={ option }
                tabIndex={ 0 }
                onKeyDown={ selectOptionAndHideOptions }
                onClick={ selectOptionAndHideOptions }
                className='app-select-item'
                key={ option }
              >
                <span className='app-select-item-option-prefix'>{ getOptionPrefix && getOptionPrefix(option) }</span>
                <span>{ option }</span>
              </div>
            )) }
            { areOptionsLoading
              && (
                <div className='app-select-item'>
                  <AppShowLoading showLoading inline />
                </div>
              ) }
          </div>
        </div>
      ) }
    </div>
  );
}
