import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';

type TabType = 'configuration' | 'email-log';

interface ITabModel {
  titleKey: string;
  key: TabType;
  to: string;
}

export default function MaintenanceEmailsShell({ activeTab, children }: { children?: ReactNode, activeTab: TabType }) {
  const { t } = useTranslation();

  const tabs = [
    { titleKey: 'Configuration', key: 'configuration', to: `/maintenance-emails/configuration` },
    { titleKey: 'EmailLog', key: 'email-log', to: `/maintenance-emails/email-log` },
  ] as ITabModel[];

  return (
    <>
      <AppContentToolbar>
        <span className='app-toolbar-breadcrump'>{ t('MaintenanceEmails') }</span>
      </AppContentToolbar>
      <div className='app-tabs'>
        { tabs.map(({ titleKey, key, to }) => (
          <Link
            className={ `app-tab ${key === activeTab ? 'app-tab-active' : ''}` }
            key={ key }
            to={ to }
          >
            { t(titleKey) }
          </Link>
        )) }
      </div>
      <div className='app-content' style={ { paddingTop: 0 } }>
        { children }
      </div>
    </>
  );
}
