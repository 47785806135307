import { useTranslation } from 'react-i18next';
import HomeShell from '../components/HomeShell';
import { CSSProperties, ReactNode, useRef, useState } from 'react';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/InstallerPersonIcon.svg';
import { ReactComponent as CompanyNameIcon } from '../../../assets/icons/company white list/company_name.svg';
import { ReactComponent as CompanyAddressIcon } from '../../../assets/icons/company white list/company_address.svg';
import { ReactComponent as CompanyPhoneIcon } from '../../../assets/icons/company white list/company_phone.svg';
import CompanyRemoveIcon from '../../../assets/icons/company white list/company_remove.svg';
import './Billing.scss';
import {
  ICompaniesFilter,
  ICompany,
  changeCompanyWhiteListStatus,
  useCompaniesLength,
} from '../../../shared/appBackend/useCompanies';
import { showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import AddToWhiteListConfirmation from './AddToWhiteListConfirmation';
import { useLazyLoad } from '../../../shared/appBackend/useLazyLoad';
import { Urls } from '../../../shared/backend/urls';
import './Billing.scss';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import { PAGE_SIZE, useInfiniteWithPagination } from '../../../shared/appBackend/useInfiniteWithPagination';
import { getUrlWithQueryParams } from '../../../shared/backend/http/http';
import AppTablePagination from '../components/AppTablePagination';
import { useAppTableScrollStyles } from '../../../styles';
import { formatCompanyAddress } from '../../../shared/formatters/formatCompanyAddress';
import AppCompaniesSelect from '../../../shared/appUIFramework/components/AppCompaniesSelect';

function CompanyDetailsItem({ children, icon, isLast, isFirst }: {
  icon: ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  children: ReactNode;
}) {
  const classes = ['app-bg-white', 'app-py-12', 'app-px-30', 'app-pl-20', 'app-w-100pcnt', 'app-min-h-20'];
  const style: CSSProperties = {};
  if (isFirst) {
    classes.push('app-border-radius-top-12px');
    style.paddingTop = 20;
  }
  if (isLast) {
    classes.push('app-border-radius-bottom-12px');
    style.paddingBottom = 20;
  }
  return (
    <div className='app-d-flex app-gap-30 app-align-items-center'>
      <div className='app-company-white-list-icon'>{icon}</div>
      <div style={style} className={classes.join(' ')}>
        {children}
      </div>
    </div>
  );
}

const isCompanyWhiteListed = (company: ICompany) => company.isBillingDisabled;

const isCompanyNotWhiteListed = (company: ICompany) => !isCompanyWhiteListed(company);

export default function Billing() {
  const { t } = useTranslation();

  const whiteListCompaniesItemsContainerRef = useRef<HTMLDivElement | null>(null);
  const whiteListedCompaniesFilter: ICompaniesFilter = {
    isBillingDisabled: true,
    excludeE2ECompanies: true,
  };
  const { companiesLength: whiteListedCompaniesTotal } = useCompaniesLength(whiteListedCompaniesFilter);
  const maxWhiteListedCompaniesPage = Math.ceil(whiteListedCompaniesTotal / PAGE_SIZE);
  const whiteListedCompaniesUrl = getUrlWithQueryParams(Urls.Companies, whiteListedCompaniesFilter);
  const {
    items: whiteListedCompanies,
    loading: isWhiteListedLoading,
    reset: refreshWhiteListedCompanies,
    page: whiteListedCompaniesPage,
    setPage: setWhiteListedCompaniesPage,
  } = useInfiniteWithPagination<ICompany>(whiteListedCompaniesUrl, maxWhiteListedCompaniesPage, {
    scrollRef: whiteListCompaniesItemsContainerRef,
  });

  const [notWhiteListedCompany, setNotWhiteListedCompany] = useState<ICompany | null>(null);

  const addToWhiteList = async (company: ICompany) => {
    try {
      const shouldAdd = await showPopup(<AddToWhiteListConfirmation />);
      if (!shouldAdd) {
        return;
      }

      await changeCompanyWhiteListStatus(company.id, {
        isWhiteListed: true,
      });

      refreshWhiteListedCompanies();
    } finally {
      company.isBillingDisabled = true;
      setNotWhiteListedCompany(null);
    }
  };

  const removeFromWhiteList = async (company: ICompany) => {
    company.isBillingDisabled = false;
    await changeCompanyWhiteListStatus(company.id, {
      isWhiteListed: false,
    });
    refreshWhiteListedCompanies();
  };

  const isScrollVisible = useAppTableScrollStyles({
    tableContentRef: whiteListCompaniesItemsContainerRef,
    isTableVisible: whiteListedCompanies.length > 0,
  });

  return (
    <HomeShell activeTab={'billing'}>
      <div className='app-d-flex'>
        <div className='app-w-50pcnt'>
          <div className='app-form-control'>
            <div className='app-form-control-label app-form-control-label-with-prefix'>
              {t('Company')}
            </div>
            <div className='app-form-control-input'>
              <div className='app-form-control-prefix'>
                <PeopleIcon />
              </div>
              <AppCompaniesSelect
                company={notWhiteListedCompany}
                setCompany={setNotWhiteListedCompany}
                filter={isCompanyNotWhiteListed}
              />
            </div>
          </div>
          <div className='app-mt-43 app-font-20'>
            <CompanyDetailsItem isFirst icon={<CompanyNameIcon />}>
              {notWhiteListedCompany?.companyName}
            </CompanyDetailsItem>
            <CompanyDetailsItem icon={<CompanyAddressIcon />}>
              <div>{formatCompanyAddress(notWhiteListedCompany?.billingAddress) || ''}</div>
              <div>{notWhiteListedCompany?.billingAddress.countryCode || ''}</div>
            </CompanyDetailsItem>
            <CompanyDetailsItem isLast icon={<CompanyPhoneIcon />}>
              <span>{notWhiteListedCompany?.primaryContact?.PhoneNumber || ''}</span>
            </CompanyDetailsItem>
          </div>
          <div className='app-d-flex app-justify-content-end app-gap-30 app-mt-26'>
            <button
              disabled={!notWhiteListedCompany}
              onClick={() => setNotWhiteListedCompany(null)}
              className='app-secondary-button'
            >
              {t('Cancel')}
            </button>
            <button
              disabled={!notWhiteListedCompany}
              onClick={() => notWhiteListedCompany && addToWhiteList(notWhiteListedCompany)}
              className='app-primary-button'
            >
              {t('Add')}
            </button>
          </div>
        </div>
        <div className='app-w-50pcnt app-position-relative'>
          <div className='app-font-20 app-weight-600 app-mb-20 app-ml-40'>
            {t('Companies')}
          </div>
          <AppShowLoading showLoading={isWhiteListedLoading}>
            <div
              ref={whiteListCompaniesItemsContainerRef}
              className='app-table app-table-content app-table-billing-white-list app-gap-20'
            >
              {whiteListedCompanies.map(whiteListedCompany => (
                <div
                  key={whiteListedCompany.id}
                  data-hover-disabled='true'
                  className='app-table-2-cols-with-icon app-table-content-row app-ml-40'
                >
                  <div>{whiteListedCompany.companyName}</div>
                  <div>{formatCompanyAddress(whiteListedCompany.billingAddress)}</div>
                  <div>
                    <div
                      onClick={() => removeFromWhiteList(whiteListedCompany)}
                      className='app-company-white-list-icon app-company-white-list-hover-white'
                    >
                      <img src={CompanyRemoveIcon} alt='Remove' />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='app-content-footer-pagination app-mt-15 app-flex-grow-1 app-d-flex app-align-items-center app-justify-content-center'>
              {whiteListedCompanies.length > 0 && isScrollVisible && (
                <AppTablePagination
                  totalPages={maxWhiteListedCompaniesPage}
                  activePageIndex={whiteListedCompaniesPage - 1}
                  onPageSelect={pageIndex => setWhiteListedCompaniesPage(pageIndex + 1)}
                />
              )}
            </div>
          </AppShowLoading>
        </div>
      </div>
    </HomeShell>
  );
}
