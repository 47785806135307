import {useTranslation} from "react-i18next";
import {AppPopupLayout} from "../../shared/appUIFramework/popup/AppPopupLayout";
import {closePopup} from "../../shared/appUIFramework/popup/AppPopup";
import {ReactComponent as SiteIcon} from "../../assets/icons/Popup Icons/TransferSitePopupIcon.svg";

export default function TransferSiteConfirmationPopup({ isNotMultipleSites }: { isNotMultipleSites: string | undefined}) {
    const {t} = useTranslation();
    const cancel = () => closePopup(false);
    const confirm = () => closePopup(true);

    return (
        <AppPopupLayout
            headerTitle={t('Popup_TransferSite_Header')}
            buttons={(
                <>
                    <button type="button" onClick={cancel}
                            className="app-secondary-button app-popup-left-button">{t('Popup_Button_Cancel')}</button>
                    <button type="button" onClick={confirm}
                            className="app-primary-button app-popup-right-button">{t('Popup_Button_Yes')}</button>
                </>
            )}
            popupBody={(
                <>
                    <div className="app-popup-image">
                        <SiteIcon/>
                    </div>
                    <div className="app-font-20 app-popup-body-text">
                          {isNotMultipleSites ? t('Popup_TransferSite_Body') : t('Popup_TransferMultipleSites_Body')}
                    </div>
                </>
            )}
        />
    );
}