import { ReactNode, RefObject, useEffect, useRef } from 'react';

export default function AppOutsideListener(
  { onClick, inputRef, children, hidden }: {
    onClick: (e: Event) => unknown;
    inputRef?: RefObject<any>;
    children?: ReactNode;
    hidden?: boolean;
  },
) {
  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const ref = inputRef || componentRef;
    const handleClickOutSide = (e: Event) => {
      const clickOnElementThatNotChild = ref.current
        && !ref.current.contains(e.target as Node);
      if (clickOnElementThatNotChild) {
        onClick(e);
      }
    };

    const timeoutId = setTimeout(() => window.addEventListener('click', handleClickOutSide), 200);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      window.removeEventListener('click', handleClickOutSide);
    };
  }, [onClick, inputRef]);

  return inputRef ? <>{ children }</> : (
    <div
      style={ {
        height: hidden ? 0 : 'unset',
        position: 'relative',
      } }
      ref={ componentRef }
    >
      { children }
    </div>
  );
}
