import { useTranslation } from 'react-i18next';
import AppPopupMenu from '../../../shared/appUIFramework/components/AppPopupMenu';
import DropdownIcon from '../../../assets/icons/Expand icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/Tick Valid icon.svg';
import '../ServiceHealth.scss';

export enum MobileTypeFilter {
  All,
  // ApnAlert, // apn alert is not used for now
  // iPhone notification
  ApnCallKit,
  // Android notification
  Fcm,
}

function getMobileTypeFilterTranslationKey(mobileTypeFilter: MobileTypeFilter) {
  const keys: Record<MobileTypeFilter, string> = {
    [MobileTypeFilter.All]: 'AllUsers',
    [MobileTypeFilter.ApnCallKit]: 'IOSUsers',
    [MobileTypeFilter.Fcm]: 'AndroidUsers',
  };

  return keys[mobileTypeFilter];
}

function MobileTypeLabel({ mobileTypeFilter }: { mobileTypeFilter: MobileTypeFilter }) {
  const { t } = useTranslation();
  return <>{ t(getMobileTypeFilterTranslationKey(mobileTypeFilter)) }</>;
}

function enumToArray(en: any): any[] {
  const keys = Object.keys(en);
  const stringIsNumber = (p: string) => !isNaN(Number(p));

  return keys.filter(stringIsNumber);
}

export default function MobileTypeDropdown({ mobileTypeFilter, setMobileTypeFilter, disabled }: {
  mobileTypeFilter: MobileTypeFilter;
  setMobileTypeFilter: (region: MobileTypeFilter) => void;
  disabled?: boolean;
}) {
  return (
    <div className='app-d-flex app-regions-dropdown'>
      <AppPopupMenu
        disabled={ disabled }
        options={ enumToArray(MobileTypeFilter).map(p => +p) }
        onOptionSelected={ setMobileTypeFilter }
        getOptionLabel={ option => {
          const isOptionSelected = option === mobileTypeFilter;
          return (
            <div className='app-d-flex app-gap-10 app-font-16-22 app-align-items-center'>
              <div className='app-d-flex app-align-items-center app-justify-content-center app-region-dropdown-checkbox'>
                { isOptionSelected && <CheckIcon /> }
              </div>
              <MobileTypeLabel mobileTypeFilter={ option } />
            </div>
          );
        } }
        render={ () => {
          return (
            <div className='app-d-flex app-justify-content-between app-align-items-center app-gap-10'>
              <MobileTypeLabel mobileTypeFilter={ mobileTypeFilter } />
              <div className='app-arrow-wrapper'>
                <img src={ DropdownIcon } alt='Dropdown Icon' className='app-dropdown-icon' />
              </div>
            </div>
          );
        } }
      />
    </div>
  );
}
