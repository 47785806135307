import { ReactNode, useEffect, useState, KeyboardEvent } from 'react';

export function callIfEscapeKeyPressed(callback: () => void) {
  return (e: KeyboardEvent<any>) => {
    if (e.key === 'Escape') callback();
  };
}

type ReactNodeConfig = { reactNode: ReactNode, disableCloseOnOverlay: boolean, disableCloseOnEscape: boolean};
type ShowType = ReactNode | ReactNodeConfig;

class PopupStateManager {
  private isOpened: boolean = false;
  private onOpen?: (popup: ShowType) => void;
  private onClose?: () => void;

  private closeWithResult?: (res?: any) => any;

  isPopupShown() {
    return this.isOpened;
  }

  async showPopup<TResult = any>(popup: ShowType): Promise<TResult> {
    if (this.isOpened) {
      this.closePopup();
    }

    this.isOpened = true;
    if (this.onOpen) {
      this.onOpen(popup);
    }

    return new Promise((resolve) => {
      this.closeWithResult = resolve;
    });
  }

  closePopup(res?: any) {
    if (!this.isOpened) {
      return;
    }

    this.isOpened = false;
    if (this.onClose) {
      this.onClose();
    }
    if (this.closeWithResult) {
      this.closeWithResult(res);
    }
  }

  addOnOpenListener(listener: (popup: ShowType) => void) {
    this.onOpen = listener;
  }

  addOnCloseListener(listener: () => void) {
    this.onClose = listener;
  }
}

const popupStateManager = new PopupStateManager();
export const isPopupShown = popupStateManager.isPopupShown.bind(popupStateManager);
export const closePopup = popupStateManager.closePopup.bind(popupStateManager);
export const showPopup = popupStateManager.showPopup.bind(popupStateManager);

export default function AppPopup() {
  const [popup, setPopup] = useState<ShowType | null>(null);

  useEffect(() => {
    popupStateManager.addOnCloseListener(() => {
      setPopup(null);
    });
    popupStateManager.addOnOpenListener((openedPopup) => {
      setPopup(openedPopup);
    });
  }, [popup]);

  const closeWhenEscapePressed = () => {
    if (popup && (popup as ReactNodeConfig).disableCloseOnEscape) {
      return;
    }

    closePopup();
  };

  const closeWhenClickOnOverlay = (event: { target: any, currentTarget: any }) => {
    if (popup && (popup as ReactNodeConfig).disableCloseOnOverlay) {
      return;
    }

    if (event.target === event.currentTarget) {
      closePopup();
    }
  };

  return (
    <>
      {popup && (
        <div
          onClick={closeWhenClickOnOverlay}
          role="button"
          tabIndex={0}
          onKeyDown={callIfEscapeKeyPressed(closeWhenEscapePressed)}
          className="app-popup-container"
        >
          {(popup as ReactNodeConfig).reactNode || (popup as ReactNode)}
        </div>
      )}
    </>
  );
}

