import { useTranslation } from 'react-i18next';
import { MockActivation, MockDeactivation, MockSessionInfo, useSupportServerAccessFeatureFlags } from './SupportServerAccess.featureFlag';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import { ReactComponent as ActivationCodeIcon } from '../../assets/supportServerAccess/activationCode.svg';
import AppSelect from '../../shared/appUIFramework/components/AppSelect';

export default function SupportServerAccessDevMode() {
  const { t } = useTranslation();
  const { featureFlags, rerenderFlags } = useSupportServerAccessFeatureFlags();

  const mockSessionOptions = [
    MockSessionInfo.Disabled,
    MockSessionInfo.PassWithMock,
    MockSessionInfo.Fail,
  ];

  const mockActivationOptions = [
    MockActivation.Disabled,
    MockActivation.PassWithMock,
    MockActivation.PassWithExpiredMock,
    MockActivation.Fail,
  ];

  const mockDeactivationOptions = [
    MockDeactivation.Disabled,
    MockDeactivation.PassWithMock,
    MockDeactivation.Fail,
  ];

  return (
    <>
      <AppContentToolbar>
        <span className='app-toolbar-breadcrump'>{t('SupportAccessRequest')} DEV</span>
      </AppContentToolbar>
      <div className='app-content'>
        <div className='app-d-flex app-flex-column app-gap-20 app-border-radius-18'>
          <div className='app-form'>
            <div className='app-form-control app-mb-20'>
              <div className='app-form-control-label app-form-control-label-with-prefix'>
                <span>{t('NegotiateBaseUrl')}</span>
              </div>
              <div className='app-form-control-input'>
                <div className='app-form-control-prefix'>
                  <ActivationCodeIcon />
                </div>
                <input
                  type='text'
                  value={featureFlags.negotiateBaseUrl}
                  className='app-form-input'
                  onChange={e => {
                    featureFlags.negotiateBaseUrl = e.target.value;
                    rerenderFlags();
                  }}
                />
              </div>
            </div>
            <div className='app-form-control app-mb-20'>
              <div className='app-form-control-label app-form-control-label-with-prefix'>
                <span>{t('GetSessionInfo')}</span>
              </div>
              <div className='app-form-control-input'>
                <div className='app-form-control-prefix'>
                  <ActivationCodeIcon />
                </div>
                <AppSelect
                  placeholder='Select mock sessoin info'
                  value={featureFlags.mockSessionInfo}
                  options={mockSessionOptions}
                  readOnly
                  onOptionSelected={value => {
                    featureFlags.mockSessionInfo = value as MockSessionInfo;
                    rerenderFlags();
                  }}
                />
              </div>
            </div>
            <div className='app-form-control app-mb-20'>
              <div className='app-form-control-label app-form-control-label-with-prefix'>
                <span>{t('ActivateSession')}</span>
              </div>
              <div className='app-form-control-input'>
                <div className='app-form-control-prefix'>
                  <ActivationCodeIcon />
                </div>
                <AppSelect
                  placeholder='Select mock activation flow'
                  value={featureFlags.mockActivation}
                  options={mockActivationOptions}
                  readOnly
                  onOptionSelected={value => {
                    featureFlags.mockActivation = value as MockActivation;
                    rerenderFlags();
                  }}
                />
              </div>
            </div>
            <div className='app-form-control app-mb-20'>
              <div className='app-form-control-label app-form-control-label-with-prefix'>
                <span>{t('MockDeactivation')}</span>
              </div>
              <div className='app-form-control-input'>
                <div className='app-form-control-prefix'>
                  <ActivationCodeIcon />
                </div>
                <AppSelect
                  placeholder='Select mock sessoin info'
                  value={featureFlags.mockDeactivation}
                  options={mockDeactivationOptions}
                  readOnly
                  onOptionSelected={value => {
                    featureFlags.mockDeactivation = value as MockDeactivation;
                    rerenderFlags();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
