const envUrl = process.env.REACT_APP_ENVIRONMENT ? `/${process.env.REACT_APP_ENVIRONMENT}` : '';

const entryBaseUrl = `https://entry.paxtoncloud.com${envUrl}/api/v1/`;
const coreBaseUrl = `https://core.paxtoncloud.com${envUrl}/api/v1/`;

export enum EntryRegion {
  UnitedKingdom = 'UnitedKingdom',
  Ireland = 'Ireland',
  UnitedStates = 'UnitedStates',
  Netherlands = 'Netherlands',
  Belgium = 'Belgium',
  Luxembourg = 'Luxembourg',
  Germany = 'Germany',
  France = 'France',
  SouthAfrica = 'SouthAfrica',
  Canada = 'Canada',
  Sweden = 'Sweden',
  Norway = 'Norway',
  Denmark = 'Denmark',
}

export type EntryRegionTypeRequired = EntryRegion | "benelux";

export type EntryRegionType = EntryRegionTypeRequired | null;

export type FunctionAppType = 'user-mgmt';

export const Urls = {
  TotalCount: (region: EntryRegionType) => {
    return `${entryBaseUrl}analytics/totalCount${region ? `?region=${region}` : ''}`;
  },
  CallNotificationTimings: () => {
    return `${entryBaseUrl}analytics/call/timings`;
  },
  ChangesForLastMonth: (region: EntryRegionType) => {
    return `${entryBaseUrl}analytics/changesForLastMonth${region ? `?region=${region}` : ''}`;
  },
  GetEmails: `${entryBaseUrl}companies/whitelist`,
  UpdateEmails: `${entryBaseUrl}companies/whitelist`,
  ServiceHealthSummary: `${entryBaseUrl}serviceHealth/summary`,
  ServiceHealthDetails: (functionApp: FunctionAppType) => {
    return `${entryBaseUrl}serviceHealth/details/${functionApp}`;
  },
  Companies: `${entryBaseUrl}companies/list`,
  CompaniesLength: `${entryBaseUrl}companies/count`,
  CompanyByCustomerReference: (customerReference: string) => `${entryBaseUrl}companies/reference/${customerReference}`,
  CompaniesUnblockAccounts: (companyId: string) => `${entryBaseUrl}companies/${companyId}/unblock`,
  CompaniesChangeWhiteListedStatus: (companyId: string) => `${entryBaseUrl}/companies/${companyId}/whitelist/status`,
  Sites: (companyId: string) => `${entryBaseUrl}sites/adminPortal/sites?companyId=${companyId}`,
  SitesTransfer: `${entryBaseUrl}sites/transfer`,
  HardwareSummary: (siteId: string) => `${entryBaseUrl}hardware/summary/${siteId}`,
  HardwareNegotiate: (siteId: string) => `${entryBaseUrl}hardware/negotiate/${siteId}`,
  HardwareFirmware: (siteId: string, panelSerial: string) => `${entryBaseUrl}hardware/${siteId}/${panelSerial}/version`,
  HardwareFirmwareNegotiate: (siteId: string) => `${entryBaseUrl}hardware/firmware/negotiate/${siteId}`,
  CallTrackerEventsStartListen: (siteId: string, serialNumber: string, enableTestMode: boolean) =>
    `${entryBaseUrl}diagnostics/start?siteId=${siteId}&panelSerial=${serialNumber}&enableTestMode=${enableTestMode}`,
  CallTrackerEventsNegotiate: (siteId: string) => `${entryBaseUrl}diagnostics/negotiate?siteId=${siteId}`,
  EmailLog: `${entryBaseUrl}entry-admin/users/email-log`,
  EmailLogTotals: `${entryBaseUrl}entry-admin/users/email-log-totals`,
  Invoices: `${coreBaseUrl}invoices/adminPortal`,
  InvoicesTotalNumber: `${coreBaseUrl}invoices/adminPortal/total`,
  Invoice: (customerReference: string, invoiceId: number, app: 'EntryApp' = 'EntryApp') =>
    `${coreBaseUrl}invoices/${customerReference}/${invoiceId}?application=${app}`,
  InternalAppsCustomerDetails: (customerReference: string) =>
    `${entryBaseUrl}internalApps/customerDetails/${customerReference}`,
  Site: (siteId?: string, companyId?: string) => (!siteId || !companyId
    ? undefined
    : `${entryBaseUrl}sites/${siteId}?companyId=${companyId}`),
  DevicesReboot: (panelSerial: string, siteId: string) => `${entryBaseUrl}config/reboot?panelSerial=${panelSerial}&siteId=${siteId}`,
  DevicesNegotiate: `${entryBaseUrl}config/negotiate`,
};
