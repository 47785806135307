import { KeyedMutator } from 'swr';
import { getUrlWithQueryParams, httpGetJson, httpPut } from '../backend/http/http';
import { EntryRegion, Urls } from '../backend/urls';
import { useSWRAndHandleErrors } from './swr/useSWRAndHandleErrors';
import { SortStringOptions } from '../appUIFramework/hooks/useSortStringOptions';

export interface ICompanyAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postCode: string;
  countryCode: string;
}

interface ICompanyPrimaryContact {
  ContactName: string;
  PhoneNumber: string;
  MobileNumber: string;
  EmailAddress: string;
  Role: string;
}

export enum CompanyAccountStatus {
  Unknown = 0,
  Ok = 1,
  Limited = 2,
  Blocked = 3,
}

export interface ICompany {
  id: string;
  companyName: string;
  isBillingDisabled: boolean;
  billingAddress: ICompanyAddress;
  primaryContact: ICompanyPrimaryContact;
  accountStatus: CompanyAccountStatus;
  accountStatusAuto: CompanyAccountStatus;
  customerReferenceId: string;
  entryRegion: EntryRegion;
}

export interface IChangeCompanyWhiteListStatusDto {
  isWhiteListed: boolean;
}

export function useCompanies(): { companies: ICompany[], mutateCompanies: KeyedMutator<ICompany[]> } {
  const url = Urls.Companies;
  const { data, mutate } = useSWRAndHandleErrors<ICompany[]>(url, httpGetJson);

  return {
    companies: data || [],
    mutateCompanies: mutate,
  };
}

export async function changeCompanyWhiteListStatus(companyId: string, dto: IChangeCompanyWhiteListStatusDto) {
  const url = Urls.CompaniesChangeWhiteListedStatus(companyId);
  await httpPut(url, dto);
}

export interface ICompaniesFilter {
  isBillingDisabled?: boolean;
  excludeE2ECompanies?: boolean;
  search?: string;
  isBlocked?: boolean;
}

export interface ICompaniesSort {
  statusSort?: SortStringOptions;
  companyNameSort?: SortStringOptions;
}

export function useCompaniesLength(queryParams: ICompaniesFilter): { companiesLength: number } {
  const url = getUrlWithQueryParams(Urls.CompaniesLength, queryParams);
  const { data } = useSWRAndHandleErrors<{ total: number }>(url, httpGetJson);

  return {
    companiesLength: data?.total || 0,
  };
}

export async function unblockCompanies(companyIds: string[]) {
  await Promise.all(companyIds.map(async companyId => {
    const url = Urls.CompaniesUnblockAccounts(companyId);
    await httpPut(url, {});
  }));
}

export enum CompanyRegion {
    UnitedKingdom = 'UnitedKingdom',
    Ireland = 'Ireland',
    UnitedStates = 'UnitedStates',
    Netherlands = 'Netherlands',
    Belgium = 'Belgium',
    Luxembourg = 'Luxembourg',
    Germany = 'Germany',
    France = 'France',
    SouthAfrica = 'SouthAfrica',
    Canada = 'Canada',
    Sweden = 'Sweden',
    Norway = 'Norway',
    Denmark = 'Denmark',
}

interface ICompanyEntry {
    id: string;
    companyName: string;
    companyLogo: string;
    createDate: string;
    termsAndConditionsAcceptedAt?: string;
    termsAndConditionsAcceptedBy?: string;
    entryRegion: CompanyRegion;
    taxCertificateBlobUri: string;
    taxCertificateFileName: string;
    taxCertificateUploadedAt: string;
    vatNumber: string;
}

export async function getCompanyByCustomerReference(customerReference: string) {
  const url = Urls.CompanyByCustomerReference(customerReference);
  return await httpGetJson<ICompanyEntry>(url);
}

export function useCompanyByCustomerReference(customerReference?: string) {
  const url = customerReference 
    ? Urls.CompanyByCustomerReference(customerReference) 
    : undefined;

  const { data } = useSWRAndHandleErrors<ICompanyEntry>(url, httpGetJson);

  return  { company: data };
}
