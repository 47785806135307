export interface IP10NeogitateResult {
  accessToken: string;
  url: string;
}

export interface IActivationCodePayload {
  activationCode: string;
}

export interface ISessionInfoResponse {
  status: string;
  requester: string;
  approvedBy: string;
  lastUpdated: number;
  siteName: string;
}

export interface ISessionActivatedResponse {
  status: string;
  requester: string;
  approvedBy: string;
  lastUpdated: number;
  username: string;
  password: string;
  expiryTime: number;
}

export interface ISessionDeactivatedResponse {
  status: string;
  requester: string;
  approvedBy: string;
  lastUpdated: number;
}

export interface IUserCredentials {
  username: string;
  password: string;
  expiryDateEpoch: number;
}

export enum P10RemoteAccessTriggerMessageIds {
  GetSessionInfo = 'GetSessionInfo',
  ActivateSession = 'ActivateSession',
  DeactivateSession = 'DeactivateSession',
}

export enum P10RemoteAccessHandlerMessageIds {
  SessionInfo = 'SessionInfo',
  SessionActivated = 'SessionActivated',
  SessionDeactivated = 'SessionDeactivated',
}

export class AppError extends Error {
  constructor(public message: string) {
    super(message);
  }
}

