import { useTranslation } from 'react-i18next';
import ErrorPage from '../shared/appUIFramework/components/ErrorPage';
import { ReactComponent as ErrorRobot404Graphic } from '../assets/errorGraphics/Error_Robot_404.svg';

export default function PageNotFound() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      errorCode={t('Errors_404')}
      errorMessage={t('Errors_Error')}
      errorGraphic={<ErrorRobot404Graphic />}
    />
  );
}
