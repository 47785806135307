import { useTranslation } from 'react-i18next';
import { useLocation, useRoute } from 'wouter';
import InvoicePdfPreview from './InvoicePdfPreview';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close-icon.svg';
import './InvoiceDetails.scss';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { useInvoice, useInvoiceStatusOptions } from '../../shared/appBackend/useInvoices';
import { getStatusColorClassName, useDownloadInvoice } from './invoicing.helper';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';

export default function InvoiceDetails() {
  const [, params] = useRoute('/invoicing/:customerReference/:invoiceId');
  const invoiceId = +params!.invoiceId!;
  const customerReference = params!.customerReference!;
  const { invoice } = useInvoice(invoiceId, customerReference);
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { getOptionLabel: getInvoiceStatusTranslation } = useInvoiceStatusOptions();

  const downloadPdf = useDownloadInvoice(invoice);

  const goToInvoicing = () => {
    setLocation('/invoicing');
  };

  return (
    <>
      <AppContentToolbar className='app-border-bottom-black'>
        <span className='app-toolbar-breadcrump '>{t('Invoices')}</span>
      </AppContentToolbar>
      <div className="app-content app-content-with-tabs app-invoice-pdf app-p-0">
        <AppShowLoading showLoading={invoice == null}>
          {invoice
            && (
              <>
                <div
                  className="app-d-flex app-justify-content-between app-bg-primary-white-color app-invoice-pdf-header"
                >
                  <div className="app-d-flex app-flex-direction-column">
                    <div
                      className={`app-invoice-pdf-currency ${getStatusColorClassName(invoice.invoiceStatusId)}`}
                    >
                      {getInvoiceStatusTranslation(invoice.invoiceStatusId)}
                    </div>
                  </div>
                  <div className="app-d-flex">
                    <div className="app-d-flex app-align-items-center app-invoice-pdf-actions">
                      <button
                        className="app-primary-button app-mw-101 app-weight-normal app-text-transform-none"
                        type="button"
                        onClick={downloadPdf}
                      >
                        {`${t('SaveTo')} ${t('PDF')}`}
                      </button>
                    </div>
                    <div className="app-text-align-right app-pl-51">
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                      <div className="app-cursor-pointer" role="button" onClick={goToInvoicing}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="app-flex-vertical-scrollable app-p-30">
                  <InvoicePdfPreview invoice={invoice} />
                </div>
              </>
            )}
        </AppShowLoading>
      </div>
    </>
  );
}

