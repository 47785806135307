import HomeShell from './components/HomeShell';
import { useTranslation } from 'react-i18next';
import './ServiceHealth.scss';
import { ReactNode, useRef, useState, Fragment } from 'react';
import { ReactComponent as ServiceError } from '../../assets/icons/service-health-icons/ServiceError.svg';
import { ReactComponent as ServiceOutage } from '../../assets/icons/service-health-icons/ServiceOutage.svg';
import { ReactComponent as BigOkIcon } from '../../assets/BigOk.svg';
import { ReactComponent as SmallOkIcon } from '../../assets/SmallOk.svg';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import {
  IServiceHealthSummary,
  ServiceStatus,
  useServiceHealthSummary,
} from '../../shared/appBackend/useServiceHealth';
import { useFormatDate } from '../../shared/formatters/formatDate';
import { useAppTableScrollStyles } from '../../styles';

const serviceStatusSmallIconMap: Record<ServiceStatus, ReactNode> = {
  [ServiceStatus.Good]: <SmallOkIcon />,
  [ServiceStatus.ServiceError]: <ServiceError />,
  [ServiceStatus.ServiceOutage]: <ServiceOutage />,
};

const serviceStatusTranslationKeyMap: Record<ServiceStatus, string> = {
  [ServiceStatus.Good]: 'Good',
  [ServiceStatus.ServiceError]: 'ServiceErrors',
  [ServiceStatus.ServiceOutage]: 'ServiceOutage',
};

const serviceStatusClassMap: Record<ServiceStatus, string> = {
  [ServiceStatus.Good]: 'app-service-health-row-status-icon-good',
  [ServiceStatus.ServiceError]: 'app-service-health-row-status-icon-warn',
  [ServiceStatus.ServiceOutage]: 'app-service-health-row-status-icon-error',
};

function ServiceStatusRow({ serviceStatus }: { serviceStatus: ServiceStatus }) {
  const { t } = useTranslation();

  return (
    <div
      className={ `${
        serviceStatusClassMap[serviceStatus]
      } app-font-20 app-weight-600 app-uppercase app-text-align-center app-py-8 app-border-radius-18` }
    >
      { t(serviceStatusTranslationKeyMap[serviceStatus]) }
    </div>
  );
}

function ServiceHealthSummary({ items }: { items?: IServiceHealthSummary[] }) {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const [expandedItem, setExpandedItem] = useState<IServiceHealthSummary | undefined>();
  const changeOrToggleItem = (item: IServiceHealthSummary) => {
    if (expandedItem && expandedItem.name === item.name) {
      setExpandedItem(undefined);
    } else {
      setExpandedItem(item);
    }
  };

  const tableContentRef = useRef<HTMLDivElement | null>(null);
  const tableHeaderRef = useRef<HTMLDivElement | null>(null);
  useAppTableScrollStyles({ tableContentRef, tableHeaderRef, isTableVisible: items != null });

  return (
      <div className='app-table app-table-service-health'>
        <div className='app-table-header-row app-table-4-cols-with-icon' ref={tableHeaderRef}>
          <span>{ t('Service') }</span>
          <span>{ t('LastUpdated') }</span>
          <span>{ t('Status') }</span>
        </div>
        <div className='app-flex-vertical-scrollable app-table-content' ref={tableContentRef}>
          { items && items.map(item => (
            <Fragment key={ item.name }>
              <div
                tabIndex={ 0 }
                data-hover-disabled='true'
                onClick={ () => changeOrToggleItem(item) }
                className='app-table-content-row app-table-4-cols-with-icon app-mb-10 app-d-flex app-align-items-center app-justify-content-between'
                role='row'
              >
                <div className='app-d-flex app-gap-10 app-align-items-center app-service-health-svg'>
                  <span>{ serviceStatusSmallIconMap[item.status] }</span>
                  <span>{ item.name }</span>
                </div>
                <div>{ formatDate(new Date(item.lastUpdatedAt)) }</div>
                <div>
                  <ServiceStatusRow serviceStatus={ item.status } />
                </div>
                <div className="app-service-health-expand-triangle-wrapper">
                  <div className="app-service-health-expand-triangle"></div>
                </div>
              </div>
              { expandedItem && expandedItem.name === item.name && (
                  <div className="app-service-health-row-details">
                    <div className="app-font-20 app-weight-600 app-mb-30">
                      <span>{t('IssuesWillAffectFollowing')}</span>
                      <span>:</span>
                    </div>
                    {expandedItem.possibleIssuesIfBroken.map(possibleIssue => (
                      <div key={possibleIssue} className="app-service-health-expanded-row app-service-health-expanded-row-item">
                        <span className="app-font-20">{possibleIssue}</span>
                      </div>
                    ))}
                  </div>
              )}
            </Fragment>
          )) }
        </div>
      </div>
  );
}

function GlobalStatus({ items }: { items?: IServiceHealthSummary[] }) {
  const { t } = useTranslation();
  const isGood = items?.every(item => item.status === ServiceStatus.Good);
  const banners = [];
  if (isGood) {
    banners.push(
      <div key={ServiceStatus.Good} className='app-service-health-banner app-service-health-banner-good'>
        <BigOkIcon />
        <span className='app-font-20'>{ t('AllServicesOperatingNormally') }</span>
      </div>,
    );
  }

  const isServiceError = items?.some(item => item.status === ServiceStatus.ServiceError);
  if (isServiceError) {
    banners.push(
      <div key={ServiceStatus.ServiceError} className='app-service-health-banner app-service-health-banner-warn'>
        <ServiceError />
        <span className='app-font-20'>{ t('SomeServiceErrors') }</span>
      </div>,
    );
  }

  const isServiceOutage = items?.some(item => item.status === ServiceStatus.ServiceOutage);
  if (isServiceOutage) {
    banners.push(
      <div key={ServiceStatus.ServiceOutage} className='app-service-health-banner app-service-health-banner-error'>
        <ServiceOutage />
        <span className='app-font-20'>{ t('SomeServiceOutages') }</span>
      </div>,
    );
  }

  const bannerWrapperClass = banners.length > 1
    ? 'app-service-health-banner-wrapper app-service-health-banner-wrapper-many'
    : 'app-service-health-banner-wrapper app-service-health-banner-wrapper-single';

  return (
    <div className={ bannerWrapperClass }>
      { banners }
    </div>
  );
}

function RegionTabs() {
  const { t } = useTranslation();
  const tabs = [
    { id: 'uk', label: t('UK') },
    { id: 'eu', label: t('Europe') },
    { id: 'usa', label: t('America') },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <div className='app-d-flex app-region-tabs'>
      { tabs.map(tab => (
        <button
          key={ tab.id }
          className={ `app-region-tab-button ${activeTab === tab.id ? 'app-region-tab-button-active' : ''}` }
          onClick={ () => setActiveTab(tab.id) }
        >
          { tab.label }
        </button>
      )) }
    </div>
  );
}

export default function ServiceHealth() {
  const serviceHealthSummary = useServiceHealthSummary();

  return (
    <HomeShell activeTab='service-health'>
      <AppShowLoading showLoading={ !serviceHealthSummary }>
        { serviceHealthSummary && (
          <>
            <GlobalStatus items={ serviceHealthSummary } />
            <RegionTabs />
            <ServiceHealthSummary items={ serviceHealthSummary || [] } />
          </>
        ) }
      </AppShowLoading>
    </HomeShell>
  );
}
